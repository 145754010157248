<template>
  <div class="share_popup">
    <div class="share_popup_first_row">
      <h3 class="stroke_1">Share this chest via</h3>
      <div class="share_buttons">
        <ShareNetwork
          title="GemChest"
          description="Create NFT chest to store your tokens securely."
          v-for="soc_media in share_social_medias_list"
          :key="soc_media.id"
          :network="soc_media.name"
          :hashtags="'gemchest,token,coin'"
          :url="shareURL"
          @close="$emit('closePopup')"
        >
          <div class="inner_block" @click="$emit('clicked')">
            <img :src="getImage(soc_media.img)" alt="#" />
          </div>
        </ShareNetwork>
        <div class="inner_block tooltip" @click="copyShareLink">
          <span class="tooltiptext stroke_1" id="myTooltip">{{
            tooltip_text
          }}</span>
          <img
            @mouseover="tooltip_text = 'Copy to clipboard'"
            :src="getImage('share_copy_icon.svg')"
            alt="#"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import shareConfig from "@/services/shareConfig";

const NETWORK_TYPE = process.env.VUE_APP_NETWORK_TYPE;
export default {
  name: "SharePopup",
  props: ["uuid"],
  computed: {
    ...mapGetters(["share_social_medias_list"]),
  },
  data() {
    return {
      tooltip_text: "",
      shareURL: `${shareConfig[NETWORK_TYPE].shareBaseUrl}${this.uuid}`,
    };
  },
  methods: {
    getImage(image) {
      return require(`@/assets/images/${image}`);
    },
    copyShareLink() {
      this.tooltip_text = "Link copied";
      navigator.clipboard.writeText(this.shareURL);
      this.$emit("clicked");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/imports/main/variables";
@import "@/assets/sass/imports/main/mixins";
@import "@/assets/sass/imports/main/general";
@import "@/assets/sass/imports/main/extendsStart";
@import "@/assets/sass/imports/main/extendsEnd";

.share_popup {
  position: relative;
  margin: 12px;
  border-radius: 20px;
  background: $siteColor;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 100px;
  z-index: 3;
  filter: drop-shadow(0px 2px 0px $black);
  -webkit-filter: drop-shadow(0px 2px 0px $black);

  h3 {
    font-family: $bold;
    color: $white;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 10px;
    text-align: center;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -13px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 15px 0 15px;
    border-color: $siteColor transparent transparent transparent;
  }

  .share_buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    .inner_block {
      margin: 0 5px;
      cursor: pointer;

      img {
        width: 32px;
        height: 32px;
      }
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;

  .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: $orangeF4;
    color: $white;
    font-family: $bold;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 130%;
    left: 60%;
    margin-left: -75px;
    opacity: 0;
    transition: ease-in-out 0.3s;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: $orangeF4 transparent transparent transparent;
    }
  }

  &:hover {
    .tooltiptext {
      visibility: visible;
      opacity: 1;
      transition: ease-in-out 0.3s;
    }
  }
}
</style>
