export default {
    ethereum: {
        image: 'ethereum.png',
        defaultToken: 'ETH',
        testnet: {
            key: 'goerli',
            blockExplorerTxBaseUrl: 'https://goerli.etherscan.io/tx/',
            openseaNftBaseUrl: 'https://testnets.opensea.io/assets/goerli/0x99e114a6BB173B9B589786ceAeBF9ceF7276Eaae/',
        },
        mainnet: {
            key: 'eth',
            blockExplorerTxBaseUrl: 'https://etherscan.io/tx/',
            openseaNftBaseUrl: 'https://opensea.io/assets',
        },
    },
}
