<template>
  <div class="prompt_to_bottom" v-if="variant === 'to_bottom'">
    <div class="prompt_title">{{ title }}</div>
    <div class="prompt_example" v-if="with_example">Example</div>
    <div class="prompt_text">
      {{ text }}
    </div>
  </div>
  <div class="prompt_to_right" v-if="variant === 'to_right'">
    <div class="prompt_title">{{ title }}</div>
    <div class="prompt_example" v-if="with_example">Example</div>
    <div class="prompt_text">
      {{ text }}
    </div>
  </div>
</template>
<script>
export default {
  props: ["title", "text", "with_example", "variant"],
}
</script>
<style lang="scss">
@import "@/assets/sass/imports/main/variables";
@import "@/assets/sass/imports/main/mixins";
@import "@/assets/sass/imports/main/general";
@import "@/assets/sass/imports/main/extendsStart";
@import "@/assets/sass/imports/main/extendsEnd";

.prompt_to_bottom, .prompt_to_right {
  position: relative;
  width: 100%;
  -webkit-box-shadow: 0 -1.5px 0 2px $black;
  -moz-box-shadow: 0 -1.5px 0 2px $black;
  box-shadow: 0 -1.5px 0 2px $black;
  padding: 10px;
  border-radius: 7px;
  background: $white;
  text-align: center;


  .prompt_title {
    font-family: $bold;
    font-size: 16px;
    line-height: 20px;
    color: $black;
  }

  .prompt_example, .prompt_text {
    font-family: $medium;
    font-size: 12px;
    line-height: 18px;
    color: $black;
  }
}

.prompt_to_bottom {
  &::after {
    position: absolute;
    bottom: -16px;
    left: calc(50% - 10.5px);
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 17px 10.5px 0 10.5px;
    border-color: $white transparent transparent transparent;
  }
}
.prompt_to_right {
  -webkit-box-shadow: -2px 0 0 2px $black;
  -moz-box-shadow: -2px 0 0 2px $black;
  box-shadow: -2px 0 0 2px $black;
  &::after {
    position: absolute;
    top: calc(50% - 10.5px);
    right: -12px;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10.5px 0 10.5px 17px;
    border-color: transparent transparent transparent $white;
  }
}
</style>