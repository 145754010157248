<template>
  <div class="notification_block" @click="notificationClickHandler">
    <div class="remove_notification">
      <img class="icon_remove" @click="removeNotification(notification_data.id)" src="@/assets/images/delete.svg"
           alt="#">
    </div>
    <div class="first_row">
      <img src="@/assets/images/gemchestlogo.png" alt="">
      <div>{{ notification_data.json.notification }}</div>
    </div>
    <div class="second_row">
      {{ getNotificationDate }}
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  props: ['notification_data'],
  computed: {
    ...mapGetters(['notifications']),
    getNotificationDate() {
      if (this.notification_data) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        const date = new Date(this.notification_data.created_at);
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month}, ${year}`;
      } else {
        return ''
      }
    }
  },
  methods: {
    ...mapActions(['updateNotifications', 'deleteNotification', "getNotifications"]),

    async notificationClickHandler(e) {
      if (
          e.target.classList[0] !== 'remove_notification'
          && e.target.classList[0] !== 'icon_remove'
      ) {
        await this.updateNotifications(localStorage.getItem('account'));
        this.$router.push(`/chest/${this.notification_data.chest_uuid}`);
        this.$store.commit('SET_OPEN_NOTIFICATION', false);
      }
    },
    async removeNotification(id) {
      await this.deleteNotification(id)
          .then(() => {
            const updatedNotifications = this.notifications.filter(item => item.id !== id);
            this.$store.commit('SET_NOTIFICATIONS', updatedNotifications);
            if (!updatedNotifications.length) {
              this.getNotifications({offset: 0})
            }
          })
          .catch(err => err);
    },
  },
}
</script>
<style lang="scss">
@import "@/assets/sass/imports/main/_variables.scss";
@import "@/assets/sass/imports/main/mixins";
@import "@/assets/sass/imports/main/general";
@import "@/assets/sass/imports/main/extendsStart";
@import "@/assets/sass/imports/main/extendsEnd";

.remove_notification {
  display: flex;
  justify-content: flex-end;
  height: 15px;

  img {
    display: none;
    width: 20px;
    height: 20px;
    transition: opacity 2s linear;
  }
}

.notification_block {
  width: 330px;
  padding: 10px 16px 15px 6px;
  border: 1px solid $bold;
  background: $white;
  border-radius: 10px;
  cursor: pointer;
  @include mediaRange($size320, $size768) {
    width: 100%;
  }

  &:hover, &:active {
    .remove_notification {
      img {
        display: inline-block;
        transition: visibility 0s 2s, opacity 2s linear;
      }
    }
  }

  .delete_notification {
    display: flex;
    justify-content: flex-end;
    color: white;
    -webkit-text-stroke: 1px $black;
    font-size: 14px;
  }

  .first_row {
    display: flex;
    align-items: center;
    margin-left: 10px;

    img {
      max-width: 50px;
      height: 40px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    div {
      font-family: $medium;
      font-size: 14px;
      color: $black;
      margin-left: 10px;
      word-break: break-word;
      line-height: 18px;
    }
  }

  .second_row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: $regular;
    font-size: 14px;
    line-height: 12px;
    color: $black;
  }
}
</style>