export default {
  "welcome_title_part1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achieving goals"])},
  "welcome_title_part2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["just got easier"])},
  "welcome_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock tokens in NFT chests and set a target price for the tokens, upon reaching which the chest will automatically unlock them."])},
  "why_gemchest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why GemChest?"])},
  "roadmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadmap"])},
  "connect_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
  "all_chests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Chests"])},
  "my_chests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Chests"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "nothing_was_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No chests found"])},
  "connect_wallet_to_see_your_chests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect your wallet to see your chests."])},
  "create_chest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Chest"])},
  "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 1"])},
  "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2"])},
  "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 3"])},
  "current_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current price"])},
  "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
  "investment_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])},
  "chest_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock For"])},
  "lock_term_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum period to keep the tokens locked in the chest"])},
  "creation_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation fee"])},
  "growth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth"])},
  "growth_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The desired increase in investment, upon reaching which all tokens will be  unlocked. For example, Unlock tokens when my invested $10 becomes $100"])},
  "token_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Price"])},
  "roi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROI"])},
  "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])}
}