const getPeriod = (startDateStr, endDateStr, shortType) => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);


    let yearDiff = endDate.getFullYear() - startDate.getFullYear();
    let monthDiff = endDate.getMonth() - startDate.getMonth();
    let dayDiff = endDate.getDate() - startDate.getDate();

    if (dayDiff < 0) {
        const tempEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), 0);
        dayDiff += tempEndDate.getDate();
        monthDiff--;
    }
    if (monthDiff < 0) {
        yearDiff--;
        monthDiff += 12;
    }


    let duration = '';
    if (yearDiff > 0) {
        duration += `${yearDiff}Y `;
    }
    if (monthDiff > 0) {
        duration += `${monthDiff}M `;
    }
    if (dayDiff > 0) {
        duration += `${dayDiff}D`;
    }

    if (!shortType) {
        if (duration.endsWith('Y ')) {
            if (yearDiff === 1) duration = duration.replace('Y ', ' Year');
            else duration = duration.replace('Y ', ' Years');
        }
        if (duration.endsWith('M ')) {
            if (monthDiff === 1) duration = duration.replace('M ', ' Month');
            else duration = duration.replace('M ', ' Months');

        }
        if (duration.endsWith('D')) {
            if (dayDiff === 1) duration = duration.replace('D', ' Day');
            else duration = duration.replace('D', ' Days');
        }
    }

    if (yearDiff || monthDiff || dayDiff) return duration;
    else return '1 Day'

}

export default getPeriod;
