import ERC20ABI from "./TokensContracts/ERC20ABI";
import { ethers } from "ethers";
import store from "@/store";
import ContractConfig from "@/contract/ContractConfig";

const NETWORK_TYPE = process.env.VUE_APP_NETWORK_TYPE;

let account = localStorage.getItem("account") || null;
let provider = null;
let signer = null;

if (window.ethereum) {
  provider = new ethers.providers.Web3Provider(window.ethereum);
  signer = provider.getSigner();

  window.ethereum.on("accountsChanged", () => {
    if (localStorage.getItem("token")) {
      store.dispatch("removeItemsFromStorage");
    }
  });
}

const Wallet = {
  async connectMetamask() {
    let account = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    let oldAccount = localStorage.getItem("account");
    if (oldAccount && oldAccount !== account[0]) {
      await store.dispatch("removeItemsFromStorage");
    }
    localStorage.setItem("account", account[0]);
    return account[0];
  },
  async signMessage(message) {
    const signature = await signer.signMessage(message);
    return signature;
  },
  async getBalance() {
    account = localStorage.getItem("account");
    const balance = await provider.getBalance(account);
    return ethers.utils.formatEther(balance);
  },
  async switchNetwork(chain_id) {
    if (!window.ethereum) {
      window.open("https://www.metamask.io", "_blank");
      throw new Error("Crypto wallet not found");
    } else {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chain_id }],
      });
    }
  },
  async changeNetwork(network_params) {
    if (!window.ethereum) {
      window.open("https://www.metamask.io", "_blank");
      throw new Error("Crypto wallet not found");
    } else {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [network_params],
      });
    }
  },
};

const Contract = {
  async getContract() {
    const contractAbi =
      ContractConfig[localStorage.getItem("current_network")][NETWORK_TYPE].abi;
    const contractAddress =
      ContractConfig[localStorage.getItem("current_network")][NETWORK_TYPE]
        .address;

    const contract = new ethers.Contract(contractAddress, contractAbi, signer);
    return contract;
  },
  async getCoinAddress(address) {
    const contract = new ethers.Contract(address, ERC20ABI, signer);
    return contract;
  },
};

export { signer, Contract, Wallet };
