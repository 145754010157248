import {io} from "socket.io-client";

const SOCKET_URL = process.env.VUE_APP_SOCKET_URL;
let token = localStorage.getItem('token');
let socket;



const connectSocket = (token, store) => {
    if (token) {
        socket = io(SOCKET_URL, {
            extraHeaders: {
                token: token,
            }
        });
        socket.on('new-chest', (data) => {
            store.dispatch('addNotification', data);
        })
        socket.on('url', (data) => {
            console.log(data);
            store.dispatch('addPdfURL',data.json.URL)
        })
    }
}


export default function webSocketPlugin() {
    return store => {
        if (token) {
            connectSocket(token,store)
        }
        window.addEventListener("storage", (e) => {
            connectSocket(e.newValue,store)
        });
    }
}
