<template>
  <div v-if="loading">
    <div class="bouncing-loader">
      <img src="@/assets/images/gemchestlogo.png" alt="logo" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["loading"],
  name: "PageLoader",
};
</script>

<style lang="scss">
@import "@/assets/sass/imports/main/variables";
@import "@/assets/sass/imports/main/mixins";
@import "@/assets/sass/imports/main/general";
@import "@/assets/sass/imports/main/extendsStart";
@import "@/assets/sass/imports/main/extendsEnd";

@keyframes bouncing-loader {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 16px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.bouncing-loader {
  display: flex;
  justify-content: center;

  img {
    width: 120px;
    height: 120px;
    @include mediaTo($size768) {
      width: 90px;
      height: 90px;
    }
  }
}

.bouncing-loader > img {
  border-radius: 50%;
  animation: bouncing-loader 1.2s infinite;
}
</style>
