export default {
    arb1: {
        chainId: `0x${Number(42161).toString(16)}`,
        chainName: "Arbitrum One",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18,
        },
        rpcUrls: [
            "https://arb1.arbitrum.io/rpc"
        ],
        blockExplorerUrls: ["https://arbiscan.io",],
    },
}
