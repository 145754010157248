/* eslint-disable */
import {createStore} from "vuex";
import * as types from "@/store/mutation-types";
import api from "@/services/api/createChest";
import webSocketPlugin from "@/plugins/webSocket";
import router from "@/router";
import NetworkConfig from "@/contract/NetworkConfig";
import networks from "@/contract/NetworkConfig/networks";
import jwt_decode from "jwt-decode";
const NETWORK_TYPE = process.env.VUE_APP_NETWORK_TYPE;

export default createStore({
    plugins: [webSocketPlugin()],
    getters: {
        steps: (state) => state.steps,
        uploaded_image: (state) => state.uploaded_image,
        imageFormat: (state) => state.imageFormat,
        current_tab_mobile_navigation: (state) =>
            state.current_tab_mobile_navigation,
        share_social_medias_list: (state) => state.share_social_medias_list,
        uuid: (state) => state.uuid,
        chestUnlockDate: (state) => state.chestUnlockDate,
        claimId: (state) => state.claimId,
        approve_token: (state) => state.approve_token,
        payEnd: (state) => state.payEnd,
        transactionFailed: (state) => state.transactionFailed,
        openNotification: (state) => state.openNotification,
        totalPrice: (state) => state.totalPrice,
        currentStep: (state) => state.currentStep,
        createData: (state) => state.createData,
        lastTenChests: (state) => state.lastTenChests,
        myChests: (state) => state.myChests,
        myChests_count: (state) => state.myChests_count,
        last_ten_chests_count: (state) => state.last_ten_chests_count,
        chest: (state) => state.chest,
        favorite_list: (state) => state.favorite_list,
        chest_types: (state) => state.chest_types,
        collectDataForChestStep1: (state) => state.collectDataForChestStep1,
        collectDataForChestStep2: (state) => state.collectDataForChestStep2,
        WalletAccount: (state) => state.WalletAccount,
        signature: (state) => state.signature,
        signatureMessage: (state) => state.signatureMessage,
        networks: () => networks,
        logged: (state) => state.logged,
        overlay: (state) => state.overlay,
        white_overlay: (state) => state.white_overlay,
        loading: (state) => state.loading,
        gift_jwt: (state) => state.gift_jwt,
        jwt_decoded_data: (state) => state.jwt_decoded_data,
        notifications: (state) => state.notifications,
        notifications_count: (state) => state.notifications_count,
        notifications_whole_count: (state) => state.notifications_whole_count,
        mobile_generating_proccess: (state) => state.mobile_generating_proccess,
        network: (state) => state.network,
        availableNetworks: (state) => state.availableNetworks,
        roi_types: (state) => state.roi_types,
        message_popup_data: (state) => state.message_popup_data,
        myChestsTotalPrice: (state) => state.myChestsTotalPrice,
        trends: (state) => state.trends,
        pdfURL: (state) => state.pdfURL,
        currentHeaderTab: (state) => state.currentHeaderTab,
    },
    actions: {
        cutZero({dispatch, commit}, {number, afterDotCount}) {
            const isFloat = dispatch("isFloat", Number(number));
            if (isFloat) return parseFloat(Number(number).toFixed(afterDotCount));
            else return Number(number);
        },
        clearMessagePopup({commit}) {
            commit(types.SET_MESSAGE_POPUP_DATA, {});
        },
        addMessagePopupData({commit}, data) {
            commit(types.SET_MESSAGE_POPUP_DATA, data);
        },
        removeItemsFromStorage() {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("account");
            sessionStorage.clear();
            router.go("/");
        },
        checkMetamaskFound({commit}) {
            if (!window.ethereum) {
                window.open("https://www.metamask.io", "_blank");
                commit("OVERLAY_ON", false);
            }
        },
        loadingOn({commit}) {
            commit(types.LOADING_ON, true);
            commit(types.WHITE_OVERLAY_ON, true);
        },
        loadingOff({commit}) {
            commit(types.LOADING_ON, false);
            commit(types.WHITE_OVERLAY_ON, false);
        },
        addPdfURL({commit}, url) {
            commit(types.SET_PDF_URL, url);
        },
        usdToCoinPrice({commit}, total) {
            let price = total / +this.state.createData.currentPrice.price;
            const token_decimal =
                this.state.collectDataForChestStep1.token.token_decimal;
            if (
                price.toString().includes(".") &&
                price.toString().split(".")[1].length > token_decimal
            ) {
                price = price.toFixed(token_decimal);
            }
            return price;
        },
        isFloat({commit}, value) {
            if (
                typeof value === "number" &&
                !Number.isNaN(value) &&
                !Number.isInteger(value)
            ) {
                return true;
            }
            return false;
        },
        addNotification({commit}, data) {
            this.state.notifications.unshift(data);
            commit(types.SET_NOTIFICATIONS_COUNT, this.state.notifications_count + 1);
        },
        getCreateData({commit}, params) {
            return new Promise((resolve, reject) => {
                api
                    .getCreateData(params)
                    .then((response) => {
                        commit(types.SET_CREATE_DATA, response.data);
                        if (!params.token) {
                            const defaultToken = response.data.token.filter(
                                (item) =>
                                    item.name === NetworkConfig[this.state.network].defaultToken
                            );
                            this.state.collectDataForChestStep1.token = defaultToken[0];
                        } else {
                            const defaultToken = response.data.token.filter(
                                (item) => item.name === params.token
                            );
                            this.state.collectDataForChestStep1.token = defaultToken[0];
                        }
                        if (!sessionStorage.getItem("step1Data")) {
                            this.state.collectDataForChestStep1.amount =
                                response.data.selectAmount[0];
                        } else {
                            const amount =
                                sessionStorage.getItem("step1Data") &&
                                JSON.parse(sessionStorage.getItem("step1Data")).amount;
                            if (amount) {
                                const filteredAmount = response.data.selectAmount.filter(
                                    (item) => item.select_amount_id == amount.select_amount_id
                                );
                                this.state.collectDataForChestStep1.amount = filteredAmount[0];
                            }
                        }
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        createChest({commit}, params) {
            return new Promise((resolve, reject) => {
                api
                    .createChest(params)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        updateCreatedChestInfo({commit}, params) {
            return new Promise((resolve, reject) => {
                api
                    .updateCreatedChestInfo(params)
                    .then((response) => {
                        this.state.myChests.unshift(response.data);
                        this.state.lastTenChests.unshift(response.data);
                        commit(types.SET_CHEST, response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getLastTenChests({commit}, param) {
            return new Promise((resolve, reject) => {
                api
                    .getLastTenChests(param)
                    .then((response) => {
                        commit(types.SET_LAST_TEN_CHESTS, response.data.allChests);
                        commit(
                            types.SET_LAST_TEN_CHESTS_COUNT,
                            response.data.filteredChestsCount
                        );
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getChestByGiftType({commit}, param) {
            return new Promise((resolve, reject) => {
                api
                    .getChestByGiftType(param)
                    .then((response) => {
                        commit(types.SET_LAST_TEN_CHESTS, response.data[0]);
                        commit(types.SET_LAST_TEN_CHESTS_COUNT, response.data[1].count);
                        commit(types.SET_MY_CHESTS, response.data[0]);
                        commit(types.SET_MY_CHESTS_COUNT, response.data[1].count);
                        this.state.myChestsTotalPrice = 0;
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getLoadedChestByGiftType({commit}, param) {
            return new Promise((resolve, reject) => {
                api
                    .getChestByGiftType(param)
                    .then((response) => {
                        commit(types.SET_LOADED_LAST_TEN_CHESTS, response.data[0]);
                        commit(types.SET_LAST_TEN_CHESTS_COUNT, response.data[1].count);
                        commit(types.SET_LOADED_MY_CHESTS, response.data[0]);
                        commit(types.SET_MY_CHESTS_COUNT, response.data[1].count);
                        this.state.myChestsTotalPrice = 0;
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getLoadedLastTenChests({commit}, query) {
            return new Promise((resolve, reject) => {
                api
                    .getLastTenChests(query)
                    .then((response) => {
                        commit(types.SET_LOADED_LAST_TEN_CHESTS, response.data.allChests);
                        commit(
                            types.SET_LAST_TEN_CHESTS_COUNT,
                            response.data.filteredChestsCount
                        );
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getMyChests({commit}, query) {
            return new Promise((resolve, reject) => {
                api
                    .getMyChests(query)
                    .then((response) => {
                        commit(types.SET_MY_CHESTS, response.data.myChests);
                        commit(
                            types.SET_MY_CHESTS_COUNT,
                            response.data.filteredChestsCount
                        );
                        this.state.myChestsTotalPrice = Number(
                            response.data.myCurrentGrowth
                        ).toFixed(2);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getLoadedMyChests({commit}, query) {
            return new Promise((resolve, reject) => {
                api
                    .getMyChests(query)
                    .then((response) => {
                        commit(types.SET_LOADED_MY_CHESTS, response.data.myChests);
                        commit(
                            types.SET_MY_CHESTS_COUNT,
                            response.data.filteredChestsCount
                        );
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getChest({commit}, params) {
            return new Promise((resolve, reject) => {
                api
                    .getChest(params)
                    .then((response) => {
                        commit(types.SET_CHEST, response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        loginMetamask({commit}, params) {
            return new Promise((resolve, reject) => {
                api
                    .loginMetamask(params)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getNonce({commit}, params) {
            return new Promise((resolve, reject) => {
                api
                    .getNonce(params)
                    .then((response) => {
                        commit(types.SET_SIGNATURE_MESSAGE, response.data.nonce);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        sendSignature({commit, dispatch}, params) {
            return new Promise((resolve, reject) => {
                api
                    .sendSignature(params)
                    .then((response) => {
                        const user_email = jwt_decode(response.data.accessToken).user_email;
                        localStorage.setItem("token", response.data.accessToken);
                        localStorage.setItem("refreshToken", response.data.refreshToken);
                        if (
                            user_email &&
                            !this.state.collectDataForChestStep2.creator_email
                        ) {
                            localStorage.setItem("user_email", user_email);
                            this.state.collectDataForChestStep2.creator_email = user_email;
                        }
                        const event = new StorageEvent("storage", {
                            key: "token",
                            newValue: response.data.accessToken,
                        });
                        window.dispatchEvent(event);
                        dispatch("getNotifications", {offset: 0});
                        commit(types.SET_ACCOUNT, localStorage.getItem("account"));
                        commit(types.SET_LOGIN, true);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        addFavorite({commit}, params) {
            return new Promise((resolve, reject) => {
                api
                    .addFavorite(params)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        removeFavorite({commit}, params) {
            return new Promise((resolve, reject) => {
                api
                    .removeFavorite(params)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getFavoriteList({commit}) {
            return new Promise((resolve, reject) => {
                api
                    .getFavoriteList()
                    .then((response) => {
                        commit(types.SET_FAVORITE_LIST, response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        claimGift({commit}, payload) {
            return new Promise((resolve, reject) => {
                api
                    .claimGift(payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getNotifications({commit}, params) {
            return new Promise((resolve, reject) => {
                api
                    .getNotifications(params)
                    .then((response) => {
                        if (params.offset === 0) {
                            commit(types.SET_NOTIFICATIONS, response.data.myNotifications);
                        } else {
                            commit(types.SET_NOTIFICATIONS, [
                                ...this.state.notifications,
                                ...response.data.myNotifications,
                            ]);
                        }
                        commit(
                            types.SET_NOTIFICATIONS_COUNT,
                            response.data.myUnreadNotificationsCount
                        );
                        commit(
                            types.SET_NOTIFICATIONS_WHOLE_COUNT,
                            response.data.myWholeNotificationsCount
                        );
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        updateNotifications({commit}, address) {
            return new Promise((resolve, reject) => {
                api
                    .updateNotifications(address)
                    .then((response) => {
                        commit(types.SET_NOTIFICATIONS_COUNT, 0);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        deleteNotification({commit}, id) {
            return new Promise((resolve, reject) => {
                api
                    .deleteNotification(id)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getTrends({commit}, dateQuery) {
            return new Promise((resolve, reject) => {
                api
                    .getTrends(dateQuery)
                    .then((response) => {
                        commit(types.SET_TRENDS, response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    },
    mutations: {
        [types.SET_STEPS](state, step) {
            const index = state.steps.indexOf(step);
            state.steps[index].disabled = false;
            for (let i = 0; i < index; i++) {
                state.steps[i].disabled = false;
            }
            for (let i = index + 1; i < state.steps.length; i++) {
                state.steps[i].disabled = true;
            }
        },
        [types.SET_CURRENT_STEP](state, data) {
            state.currentStep = data;
        },
        [types.SET_UUID](state, id) {
            state.uuid = id;
        },
        [types.SET_MOBILE_NAVIGATION_TAB](state, tab) {
            state.current_tab_mobile_navigation = tab;
        },
        [types.SET_LOGIN](state, val) {
            state.logged = val;
        },
        [types.SET_CLAIM_ID](state, id) {
            state.claimId = id;
        },

        [types.SET_CREATE_DATA](state, data) {
            state.createData = data;
        },
        [types.SET_TOTAL](state, total) {
            state.totalPrice = total;
        },
        [types.SET_UPLOADED_IMAGE](state, image) {
            state.uploaded_image = image;
        },
        [types.SET_APPROVE_TOKEN](state, approve_token) {
            state.approve_token = approve_token;
        },
        [types.SET_PAY_END](state, pay_end) {
            state.payEnd = pay_end;
        },
        [types.SET_TRANSACTION_FAILED](state, transactionFailed) {
            state.transactionFailed = transactionFailed;
        },
        [types.SET_ACCOUNT](state, account) {
            state.WalletAccount = account;
        },
        [types.SET_LAST_TEN_CHESTS](state, chests) {
            state.lastTenChests = chests;
        },
        [types.SET_LAST_TEN_CHESTS_COUNT](state, count) {
            state.last_ten_chests_count = count;
        },
        [types.SET_MY_CHESTS_COUNT](state, count) {
            state.myChests_count = count;
        },
        [types.SET_LOADED_LAST_TEN_CHESTS](state, chests) {
            state.lastTenChests = [...state.lastTenChests, ...chests];
        },
        [types.SET_MY_CHESTS](state, chests) {
            state.myChests = chests;
        },
        [types.SET_LOADED_MY_CHESTS](state, chests) {
            state.myChests = [...state.myChests, ...chests];
        },
        [types.OVERLAY_ON](state, bool) {
            state.overlay = bool;
        },
        [types.WHITE_OVERLAY_ON](state, bool) {
            state.white_overlay = bool;
        },
        [types.LOADING_ON](state, bool) {
            state.loading = bool;
        },
        [types.SET_CHEST](state, chest) {
            state.chest = chest;
        },
        [types.SET_PDF_URL](state, url) {
            state.pdfURL = url;
            state.pdfURL = url;
        },
        [types.SET_MESSAGE_POPUP_DATA](state, data) {
            state.message_popup_data = data;
        },
        [types.SET_FAVORITE_LIST](state, favorites) {
            state.favorite_list = favorites;
        },
        [types.SET_SIGNATURE](state, sign) {
            state.signature = sign;
        },
        [types.SET_SIGNATURE_MESSAGE](state, message) {
            state.signatureMessage = message;
        },
        [types.SET_OPEN_NOTIFICATION](state, newVal) {
            state.openNotification = newVal;
        },
        [types.SET_GIFT_JWT](state, jwt) {
            state.gift_jwt = jwt;
        },
        [types.SET_DECODED_JWT_DATA](state, decoded_jwt) {
            state.jwt_decoded_data = decoded_jwt;
        },
        [types.SET_NOTIFICATIONS](state, notifications) {
            state.notifications = notifications;
        },
        [types.SET_NOTIFICATIONS_COUNT](state, count) {
            state.notifications_count = count;
        },
        [types.SET_NOTIFICATIONS_WHOLE_COUNT](state, count) {
            state.notifications_whole_count = count;
        },
        [types.SET_NETWORK](state, ntw) {
            state.network = ntw;
        },
        [types.SET_MOBILE_GENERATING_PROCCESS](state, value) {
            state.mobile_generating_proccess = value;
        },
        [types.SET_CHEST_TYPE](state, type) {
            state.chest_types = type;
        },
        [types.SET_TRENDS](state, trends) {
            state.trends = trends;
        },
        [types.SET_CURRENT_HEADER_TAB](state, tab) {
            state.currentHeaderTab = tab;
        },
        [types.SET_COLLECT_DATA_STEP_1](state, data) {
            state.collectDataForChestStep1 = {
                ...state.collectDataForChestStep1,
                ...data,
            };
        },
    },
    state: {
        overlay: false,
        white_overlay: false,
        loading: false,
        signature: "",
        signatureMessage: "",
        notifications_whole_count: 0,
        mobile_generating_proccess: false,
        imageFormat: ".svg",
        uuid: "",
        claimId: "",
        currentHeaderTab: {},
        network: localStorage.getItem("current_network") || "polygon",
        availableNetworks: {
            polygon: {
                chainName: "polygon",
                chainImage: "polygon.png",
            },
            ethereum: {
                chainName: "ethereum",
                chainImage: "ethereum.png",
            },
            arbitrum: {
                chainName: "arbitrum",
                chainImage: "arbitrum.png",
            },
            optimism: {
                chainName: "optimism",
                chainImage: "optimism.png",
            },
        },
        pdfURL: "",
        current_tab_mobile_navigation:
            sessionStorage.getItem("current_tab_mobile_navigation") || "create_chest",
        transactionFailed: false,
        chestUnlockDate: {
            start_date: "",
            end_date: "",
        },
        collectDataForChestStep1: JSON.parse(
            sessionStorage.getItem("step1Data")
        ) || {
            token: {},
            amount: {},
            date: 0,
            targetPrice: {
                tokenPrice: "",
                reward: "",
                targetX: "",
            },
            swapToUSDC: false,
            lockFor: "",
        },
        notification_count: 0,
        collectDataForChestStep2: JSON.parse(
            sessionStorage.getItem("step2Data")
        ) || {
            chestText: "",
            customisation: true,
            packaging: false,
            gift_with_wallet: true,
            image: "",
            chestLook: {},
            chestBackground: {},
            packagingSkin: {},
            creator_email: localStorage.getItem("user_email") || "",
            sender: "",
            sender_email: "",
            recipient: "",
            recipient_email: "",
            wallet_address: "",
        },
        uploaded_image: "",
        lastTenChests: [],
        trends: [],
        myChests: [],
        chest: [],
        last_ten_chests_count: 0,
        myChests_count: 0,
        myChestsTotalPrice: 0,
        favorite_list: [],
        gift_jwt: "",
        jwt_decoded_data: {},
        chest_types: [
            {
                id: 0,
                type: "All",
                key: "",
            },
            {
                id: 1,
                type: "Common",
                key: "common",
            },
            {
                id: 2,
                type: "Uncommon",
                key: "uncommon",
            },
            {
                id: 3,
                type: "Rare",
                key: "rare",
            },
            {
                id: 4,
                type: "Legendary",
                key: "Legendary",
            },
            {
                id: 5,
                type: "Epic",
                key: "Epic",
            },
        ],
        message_popup_data: {},
        WalletAccount: localStorage.getItem("account") || null,
        approve_token: false,
        payEnd: false,
        createChestData: {},
        totalPrice: JSON.parse(sessionStorage.getItem("totalPrice")) || 0,
        createData: {},
        openNotification: false,
        currentStep: JSON.parse(sessionStorage.getItem("currentStep")) || 1,
        logged: false,
        notifications: [],
        roi_types: [
            {
                min: 100,
                max: 300,
                type: "common",
            },
            {
                min: 300,
                max: 600,
                type: "uncommon",
            },
            {
                min: 600,
                max: 900,
                type: "rare",
            },
            {
                min: 900,
                max: 1200,
                type: "legendary",
            },
            {
                min: 1200,
                max: 1 / 0,
                type: "epic",
            },
        ],
        share_social_medias_list: [
            {
                id: 2,
                name: "twitter",
                img: "twitter_share_icon.svg",
            },
            {
                id: 3,
                name: "telegram",
                img: "telegram_share_icon.png",
            },
            {
                id: 4,
                name: "facebook",
                img: "facebook_share_icon.svg",
            },
        ],
        steps: JSON.parse(sessionStorage.getItem("steps")) || [
            {
                id: 1,
                title: "1. Step",
                disabled: false,
                active: true,
            },
            {
                id: 2,
                title: "2. Step",
                disabled: true,
                active: false,
            },
            {
                id: 3,
                title: "3. Step",
                disabled: true,
                active: false,
            },
        ],
    },
});
