<template>
  <div4>
    <div
        v-show="!checked && !disabled"
        :class="disabled ? 'disabled' : 'custom__checkbox'"
        @click="$emit('checkboxEvent')"
        class="stroke_1"
    >
    </div>
    <div
        v-show="checked || disabled"
        :class="disabled ? 'disabled' : 'custom__checkbox_checked'"
        @click="$emit('checkboxEvent')"
        class="stroke_1"
    >
      <img
          :src="
        disabled
          ? getImage('checkbox_white_mark.png')
          : getImage('checkbox_white_mark.png')
      "
          alt=""
      />
    </div>
  </div4>
</template>
<script>
export default {
  props: ["checked", "disabled"],
  methods: {
    getImage(image) {
      return require(`@/assets/images/${image}`);
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/sass/imports/main/variables";

.custom__checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 1px solid $black;
  border-radius: 4px;
  filter: drop-shadow(0px 3px 0px $black);
  -webkit-filter: drop-shadow(0px 3px 0px $black);
}
.disabled,.custom__checkbox_checked {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 1px solid $black;
  border-radius: 4px;
  filter: drop-shadow(0px 3px 0px $black);
  -webkit-filter: drop-shadow(0px 3px 0px $black);
  cursor: pointer;
  background: $green5A;
  img {
    width: 22px;
    height: 22px;
  }
}
.custom__checkbox {
  cursor: pointer;
  background: $white;
}

.disabled {
  background: $grayD9;
  img {
    filter: drop-shadow(0px 0px 0px $black);
    -webkit-filter: drop-shadow(0px 0px 0px $black);

  }
}
</style>
