import {createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: "/",
        name: "create_chest",
        meta: {
            title: "Create Chest",
            // requiresAuth: true,
        },
        component: () =>
            import(
                /* webpackChunkName: "Create Chest" */ "@/views/CreateChestPage/CreateChest"
                ),
    },
    {
        path: "/account",
        name: "account",
        meta: {
            title: "Account",
            // requiresAuth: true,
        },
        component: () =>
            import(
                /* webpackChunkName: "Account Page" */ "@/views/Account/AccountPage"
                ),
    },
    {
        path: "/chest/:id",
        name: "chest",
        meta: {
            title: "Chest",
            // requiresAuth: true,
        },
        component: () =>
            import(
                /* webpackChunkName: "Share Chest" */ "@/views/ShareChest/ShareChest"
                ),
    },
    {
        path: "/:uuid/:jwt",
        name: "gift",
        meta: {
            title: "Claim Gift",
            // requiresAuth: true,
        },
        component: () =>
            import(
                /* webpackChunkName: "Share Chest" */ "@/views/ClaimGift/ClaimGift.vue"
                ),
    },
    {
        path: '/:pathMatch(.*)*',
        name: "404",
        meta: {
            title: "Not Found",
            // requiresAuth: true,
        },
        component: () =>
            import(
                /* webpackChunkName: "Share Chest" */ "@/views/NotFoundPage/NotFoundPage.vue"
                ),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    const token = localStorage.getItem('token');

    if (!token && to.name == 'account') {
        next('/');
    } else {
        next()
    }
});

export default router;
