export const SET_STEPS = "SET_STEPS";
export const SET_UPLOADED_IMAGE = "SET_UPLOADED_IMAGE";
export const SET_UUID = "SET_UUID";
export const SET_MOBILE_NAVIGATION_TAB = "SET_MOBILE_NAVIGATION_TAB";
export const SET_CLAIM_ID = "SET_CLAIM_ID";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export const SET_OPEN_NOTIFICATION = "SET_OPEN_NOTIFICATION";
export const SET_APPROVE_TOKEN = "SET_APPROVE_TOKEN";
export const SET_PAY_END = "SET_PAY_END";
export const SET_TRANSACTION_FAILED = "SET_TRANSACTION_FAILED";
export const SET_TOTAL = "SET_TOTAL";
export const SET_CREATE_DATA = "SET_CREATE_DATA";
export const SET_LAST_TEN_CHESTS = "SET_LAST_TEN_CHESTS";
export const SET_LOADED_LAST_TEN_CHESTS = "SET_LOADED_LAST_TEN_CHESTS";
export const SET_MY_CHESTS = "SET_MY_CHESTS";
export const SET_LAST_TEN_CHESTS_COUNT = "SET_LAST_TEN_CHESTS_COUNT";
export const SET_MY_CHESTS_COUNT = "SET_MY_CHESTS_COUNT";
export const SET_LOADED_MY_CHESTS = "SET_LOADED_MY_CHESTS";
export const SET_CHEST = "SET_CHEST";
export const SET_FAVORITE_LIST = "SET_FAVORITE_LIST";
export const SET_COLLECT_DATA_STEP_1 = "SET_COLLECT_DATA_STEP_1";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_SIGNATURE = "SET_SIGNATURE";
export const SET_SIGNATURE_MESSAGE = "SET_SIGNATURE_MESSAGE";
export const SET_LOGIN = "SET_LOGIN";
export const OVERLAY_ON = "OVERLAY_ON";
export const WHITE_OVERLAY_ON = "WHITE_OVERLAY_ON";
export const LOADING_ON = "LOADING_ON";
export const SET_GIFT_JWT = "SET_GIFT_JWT";
export const SET_DECODED_JWT_DATA = "SET_DECODED_JWT_DATA";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_NOTIFICATIONS_COUNT = "SET_NOTIFICATIONS_COUNT";
export const SET_NOTIFICATIONS_WHOLE_COUNT = "SET_NOTIFICATIONS_WHOLE_COUNT";
export const SET_NETWORK = "SET_NETWORK";
export const SET_MOBILE_GENERATING_PROCCESS = "SET_MOBILE_GENERATING_PROCCESS";
export const SET_MESSAGE_POPUP_DATA = "SET_MESSAGE_POPUP_DATA";
export const SET_CHEST_TYPE = "SET_CHEST_TYPE";
export const SET_TRENDS = "SET_TRENDS";
export const SET_CURRENT_HEADER_TAB = "SET_CURRENT_HEADER_TAB";
export const SET_PDF_URL = "SET_PDF_URL";
