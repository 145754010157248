<template>
<!--  <div class="footer">-->
<!--    <div class="page_container">-->
<!--      <div class="footer_info">-->
<!--        <div class="social_media">-->
<!--          <ul>-->
<!--            <li class="footer_logo">-->
<!--              <img src="@/assets/images/gemchestlogo.png" alt=""/>-->
<!--            </li>-->
<!--            <li v-for="item in social_media_icons" :key="item.alt">-->
<!--              <a target="_blank" :href="item.href"><img :src="getImage(item.src)" alt=""/></a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--&lt;!&ndash;        <div class="gemchest_location">Mina Rashid, Dubai United Arab Emirates</div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="gemchest_mail">&ndash;&gt;-->
<!--&lt;!&ndash;          <a href="mailto:gemchestio@gmail.com">gemchestio@gmail.com</a>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <div class="copyright_footer">
    <div class="page_container">
      <div class="stroke_1 copyright">
        Copyright © {{ new Date().getFullYear() }} GemChest.io All rights reserved
      </div>
      <div class="privacy_policy">
        <div class="stroke_1 privacy" >Privacy Policy</div>
        <div class="stroke_1 terms">Terms & Conditions</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      social_media_icons: [
        // {
        //   src: "footer_discord.svg",
        //   href: "#",
        //   alt: "discord",
        // },
        {
          src: "footer_youtube.svg",
          href: "https://www.youtube.com/channel/UCkuST5AEOdjgXp6GY2sdSvw",
          alt: "youtube",
        },
        {
          src: "footer_twitter.svg",
          href: "https://twitter.com/gemchestio",
          alt: "twitter",
        },
        {
          src: "footer_telegram.svg",
          href: "https://t.me/gemchest",
          alt: "telegram",
        },
        // {
        //   src: "footer_facebook.svg",
        //   href: "#",
        //   alt: "facebook",
        // },
      ],
    };
  },
  methods: {
    getImage(image) {
      return require(`@/assets/images/${image}`);
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/sass/imports/main/variables";
@import "../../assets/sass/imports/main/mixins";
@import "../../assets/sass/imports/main/general";
@import "../../assets/sass/imports/main/extendsStart";
@import "../../assets/sass/imports/main/extendsEnd";


.gemchest_location,.gemchest_mail > a {
  width: 268px;
  font-family: $medium;
  font-size: 18px;
  line-height: 119.9%;
  letter-spacing: 0.04em;
  color: $white;
  text-decoration: none;
}
.footer {

  padding: 30px 0;
  background-color: #978bf5;
}

.footer_logo {
  img {
    width: 64px;
    height: 64px;
  }
}

.footer_info {
  .social_media {
    ul {
      display: flex;
      align-items: center;
      list-style: none;

      li {
        margin-right: 20px;

        a {
          display: block;
          @include siteOpacityHover;
        }
      }
    }
  }
}

.footer_contact {
  display: flex;

  div,
  a {
    width: 268px;
    font-family: $medium;
    font-size: 18px;
    line-height: 119.9%;
    letter-spacing: 0.04em;
    color: $white;
    text-decoration: none;
  }
}


.footer_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1100px;
}

.copyright_footer {
  background: $siteColor;
  padding: 10px 0;

  .page_container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .copyright, .privacy_policy {
      font-family: $medium;
      font-size: 16px;
      line-height: 119.9%;
      letter-spacing: 0.04em;
      color: $white;
      @include mediaRange($size768, $size960) {
        font-size: 14px;
      }
      @include mediaRange($size320, $size768) {
        font-size: 6px;
        line-height: 119.9%;
      }
    }

    .privacy_policy {
      display: flex;
      justify-content: center;
      align-items: center;
      .privacy,.terms {
        cursor: pointer;
      }
      .privacy::after {
        content: "";
        border-right: 1px solid $black;
        margin: 0 15px;
        @include mediaRange($size320, $size768) {
          margin: 0 6px;
        }
      }
    }

  }
}
</style>
