import polygonConfig from "@/contract/NetworkConfig/polygon/polygon.config";
import ethereumConfig from "@/contract/NetworkConfig/ethereum/ethereum.config";
import arbitrumConfig from "@/contract/NetworkConfig/arbitrum/arbitrum.config";
import optimismConfig from "@/contract/NetworkConfig/optimism/optimism.config";

const NetworkConfig = {
    ...polygonConfig,
    ...ethereumConfig,
    ...arbitrumConfig,
    ...optimismConfig
}

export default NetworkConfig;
