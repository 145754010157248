export default {
    eth: {
        chainId: `0x${Number(1).toString(16)}`,
        chainName: "Ethereum Mainnet",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18,
        },
        rpcUrls: [
            "https://api.mycryptoapi.com/eth",
            "https://cloudflare-eth.com",
        ],
        blockExplorerUrls: ["https://etherscan.io"],
    },
}
