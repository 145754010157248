<template>
  <button :class="`stroke_1 ${type}`" @click="$emit('submit')">
    <slot></slot>
  </button>
</template>
<script>
export default {
  props: ['type'],
};
</script>
<style lang="scss">
@import "@/assets/sass/imports/main/variables";
@import "@/assets/sass/imports/main/mixins";
@import "@/assets/sass/imports/main/general";
@import "@/assets/sass/imports/main/extendsStart";
@import "@/assets/sass/imports/main/extendsEnd";

.next-button-disabled,
.next-button-active {
  font-family: $bold;
  font-size: 22px;
  line-height: 34px;
  color: $white;
  border: 2px solid $black;
  border-radius: 7px;
  @include mediaRange($size320, $size768) {
    zoom: 80%;
    font-size: 20px;
  }

}

.next-button-disabled {
  background: $grayD9;
}

.next-button-active {
  background: $orangeF4;
  transition: linear 0.2s;
  cursor: pointer;
  &:hover,&:active {
    font-size: 24px;
    transition: .2s ease-in-out;
    @include siteBgHover;
  }
}
</style>
