<template>
  <div
    class="wrapp"
    v-click-away="closeShareBar"
    :class="share_bar ? 'showShare' : ''"
  >
    <div class="share_chest" v-if="share_bar">
      <SharePopup
        :uuid="this.uuid"
        @closePopup="closeShareBar"
        @clicked="closeShareBar"
      />
    </div>
    <div
      v-show="opened"
      @click="flipHandler"
      ref="build_chest_look"
      :class="share_bar ? 'showShare' : ''"
    >
      <div
        class="build_chest_look"
        :style="
          card_background
            ? `background: url(${getBgImage(
                card_background
              )}) center center / 110% no-repeat;`
            : ''
        "
      >
        <div
          class="selected_bg"
          :style="
            card_background
              ? `background: url(${getBgImage(
                  card_background
                )}) center center / 110% no-repeat;`
              : ''
          "
        >
          <div
            class="first_row"
            :style="
              create_variant ? 'margin-bottom: 37px' : 'margin-bottom: 5px'
            "
          >
            <div class="token_params">
              <img
                src="@/assets/images/gemchestlogo.png"
                alt="#"
                class="gemchest_logo"
              />
              <div class="roi_and_type">
                <div class="chest_type_text stroke_2">
                  {{ chest_type }} <span>Chest</span>
                </div>
                <div class="token_info_name stroke_1">
                  {{ chain_name }}
                  <span
                    >{{
                      Math.floor((target_growth / investment_cost) * 100 - 100)
                    }}%
                  </span>
                  ROI
                </div>
              </div>
            </div>
            <div class="show_when_chest_gift" v-if="gift">
              <img src="@/assets/images/gift.png" alt="" />
            </div>
            <div
              class="share_and_favorite_icons"
              style="padding-top: 0px"
              v-if="!create_variant"
            >
              <img
                class="polygon_icon stroke_1"
                src="@/assets/images/opensea.svg"
                alt=""
                @click="viewNftInOpensea"
              />
            </div>
          </div>

          <div
            class="second_row"
            :style="
              create_variant ? 'margin-bottom: 25px' : 'margin-bottom: 5px'
            "
          >
            <div
              class="build_chest"
              :style="
                !withCustomisation
                  ? 'margin-top: 20px;width: 100%;justify-content: center; '
                  : !chest_card_image && !chest_card_text
                  ? 'margin-top: 10px;width: 100%;justify-content: center;'
                  : ''
              "
            >
              <div
                class="chest_lock_card_token_image"
                :style="
                  create_variant
                    ? 'width: 42px; height: 42px; margin-right: 40px; margin-top: 7px; '
                    : ''
                "
              >
                <img :src="chain_logo ? getImage(chain_logo) : ''" alt="" />
              </div>
              <img
                :style="create_variant ? 'height: 110%;' : 'height: 100%;'"
                :src="chest_look ? getImage(chest_look) : ''"
                alt="chest"
              />
            </div>
            <div
              class="customization_comment_image"
              v-if="withCustomisation"
              :style="create_variant ? 'height: 150px;' : 'height: 138px;'"
            >
              <div v-if="chest_card_image">
                <div
                  class="upload_image"
                  :style="!chest_card_text ? 'width: 160px; height: 118px' : ''"
                >
                  <div
                    :style="
                      create_variant
                        ? `background-image: url(${chest_card_image});
                                      background-position: center;
                                      background-size: cover;
                                      background-repeat: no-repeat;`
                        : `background-image: url(${getUploadImage(
                            chest_card_image
                          )});
                                      background-position: center;
                                      background-size: cover;
                                      background-repeat: no-repeat;`
                    "
                  ></div>
                </div>
              </div>

              <div class="chest_comment" v-if="chest_card_text">
                {{ chest_card_text }}
              </div>
            </div>
          </div>

          <div class="third_row">
            <div class="chest_claim_await_time">
              <div class="oclock_image">
                <img src="@/assets/images/Time-gif.gif" alt="" />
              </div>
              <div class="show_await_time nft_version_show_await_time stroke_1">
                <div class="s_e_date">
                  {{ getDateInterval() }}
                </div>
                <div
                  class="await_date"
                  :style="`width:${
                    create_variant
                      ? '5%'
                      : date_progress >= 100
                      ? '100%'
                      : date_progress
                  };`"
                ></div>
              </div>
            </div>
            <div class="chest_unique_id stroke_1">
              {{ card_unique_id ? card_unique_id : "" }}
            </div>
          </div>

          <div class="investment_row">
            <div class="investment_text stroke_1">
              <p>Amount</p>
              <p>
                {{
                  investment_quantity &&
                  parseFloat(investment_quantity.toFixed(10))
                }}
              </p>
            </div>
            <div class="investment_options">
              <div class="cost stroke_1">
                <div class="title">Cost</div>
                <div class="value">
                  <span>$ </span>{{ investment_cost /*sunduki giny usd*/ }}
                </div>
              </div>
              <div class="bought_price stroke_1">
                <div class="title">{{ chain_name }} (1)</div>
                <div class="value">
                  <span>$ </span
                  >{{ pricesForCuttingZero.bought_price /*tokeni giny */ }}
                </div>
              </div>
            </div>
          </div>

          <div class="current_state_row" v-if="!create_variant">
            <div class="current_state_text stroke_1">Current</div>
            <div class="current_state_options">
              <div class="cost stroke_1">
                <div class="title">Growth</div>
                <div class="value">
                  <span>$ </span
                  >{{
                    /*inchqan e achel usd-ov orinak 10-50$*/
                    +current_growth
                  }}
                </div>
              </div>
              <div class="bought_price stroke_1">
                <div class="title">Price</div>
                <div class="value">
                  <span>$ </span
                  >{{
                    /*tokeni giny henc hima*/
                    pricesForCuttingZero.current_price
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="targets_row">
            <div class="targets_text stroke_1">
              <p>Target</p>
              <p>
                {{ !!swap ? "Swap" : "Unlock" }}
              </p>
            </div>
            <div class="targets_options">
              <div class="cost stroke_1">
                <div class="title">Growth</div>
                <div class="value"><span>$ </span>{{ +target_growth }}</div>
              </div>
              <div class="bought_price stroke_1">
                <div class="title">Price</div>
                <div class="value"><span>$ </span>{{ +target_price }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="!opened"
      @click="flipHandler"
      ref="build_chest_look_hidden_variant"
      :class="share_bar ? 'showShare' : ''"
    >
      <div
        class="build_chest_look"
        :style="
          card_background
            ? `background: url(${getBgImage(
                card_background
              )}) center center / 110% no-repeat; width: 375px; height: 388px`
            : ''
        "
      >
        <div
          class="selected_bg"
          :style="
            card_background
              ? `background: url(${getBgImage(
                  card_background
                )}) center center / 110% no-repeat;`
              : ''
          "
        >
          <div
            class="chest_ribbon"
            :style="`
            background-image: url(${getRibbonImage()});
            background-position: center;
            background-size: contain;
          `"
            v-if="status && (status === 'opened_time' || status === 'opened')"
          >
            <p>{{ getRibbonText() }}</p>
          </div>
          <div
            class="first_row"
            :style="
              create_variant ? 'margin-bottom: 20px' : 'margin-bottom: 5px'
            "
          >
            <div class="token_params">
              <!--              <div class="token_info_icon">-->
              <!--                <img :src="getImage(chain_logo)" alt="#"/>-->
              <img
                src="@/assets/images/gemchestlogo.png"
                alt="#"
                class="gemchest_logo"
              />
              <!--              </div>-->
              <div class="roi_and_type">
                <div class="chest_type_text cover_chest_type_text stroke_2">
                  {{ chest_type }} <span>Chest</span>
                </div>
                <div class="token_info_name stroke_1">
                  {{ chain_name }}
                  <span
                    >{{
                      Math.floor((target_growth / investment_cost) * 100 - 100)
                    }}%
                  </span>
                  ROI
                </div>
              </div>
            </div>
            <div class="show_when_chest_gift" v-if="gift">
              <img src="@/assets/images/gift.png" alt="" />
            </div>
            <div
              class="share_and_favorite_icons"
              style="padding-top: 20px"
              v-if="!create_variant"
            >
              <div
                class="icon_share stroke_1"
                @click="shareHandler"
                v-if="!disableShare"
              ></div>
              <div class="favorite_prompt" v-if="showPrompt">
                <InputPrompt
                  title="Connect Wallet"
                  :with_example="false"
                  variant="to_right"
                />
              </div>
              <div
                class="icon_favorite stroke_1"
                :style="
                  chest_favorite && disableShare
                    ? 'color: #f4bf4e; margin-top: -25px;'
                    : disableShare
                    ? 'margin-top: -25px;'
                    : chest_favorite
                    ? 'color: #f4bf4e'
                    : ''
                "
                @click="favoriteAddOrRemoveHandler"
              ></div>
            </div>
          </div>

          <div class="cover_second_row">
            <div
              class="build_chest"
              :style="
                !withCustomisation
                  ? 'margin-top: 20px;width: 100%;justify-content: center; '
                  : !chest_card_image && !chest_card_text
                  ? 'margin-top: 5px;width: 100%;justify-content: center;'
                  : ''
              "
            >
              <div class="chest_lock_card_token_cover_image">
                <img :src="chain_logo ? getImage(chain_logo) : ''" alt="" />
              </div>
              <img :src="chest_look ? getImage(chest_look) : ''" alt="chest" />
            </div>
            <div
              class="customization_comment_image"
              v-if="withCustomisation"
              :style="create_variant ? 'height: 150px;' : 'height: 140px;'"
            >
              <div v-if="chest_card_image">
                <div
                  class="upload_image"
                  :style="!chest_card_text ? 'width: 160px; height: 118px' : ''"
                >
                  <div
                    :style="
                      create_variant
                        ? `background-image: url(${chest_card_image});
                                      background-position: center;
                                      background-size: cover;
                                      background-repeat: no-repeat;`
                        : `background-image: url(${getUploadImage(
                            chest_card_image
                          )});
                                      background-position: center;
                                      background-size: cover;
                                      background-repeat: no-repeat;`
                    "
                  ></div>
                </div>
              </div>
              <div
                class="chest_comment"
                :style="!chest_card_image ? 'width: 150px' : ''"
                v-if="chest_card_text"
              >
                {{ chest_card_text }}
              </div>
            </div>
          </div>

          <div class="cover_third_row">
            <div class="show_x_progress">
              <div class="start_x stroke_1">1x</div>
              <div
                class="current_x stroke_1"
                v-show="openTimeX || current_growth >= investment_cost"
              >
                <p>
                  {{
                    openTimeX
                      ? openTimeX
                      : (current_growth / investment_cost).toFixed(2)
                  }}x
                </p>
              </div>
              <div class="end_x stroke_1">
                {{ parseFloat((target_growth / investment_cost).toFixed(1)) }}x
              </div>
            </div>
            <div class="chest_claim_await_time">
              <div class="oclock_image">
                <img src="@/assets/images/Time-gif.gif" alt="" />
              </div>
              <div class="show_await_time stroke_1">
                <div class="s_e_date">
                  {{ dateDifference }}
                </div>
                <div
                  class="await_date"
                  :style="`width:${
                    create_variant
                      ? '5%'
                      : date_progress >= 100
                      ? '100%'
                      : date_progress
                  };`"
                ></div>
              </div>
            </div>
          </div>

          <div class="last_row_wrapper">
            <div
              class="last_row stroke_1"
              v-if="!create_variant"
              :style="
                create_variant ? 'margin-top: 11px' : 'margin-bottom: 21px'
              "
            >
              <!--            <div>-->
              <!--              <img-->
              <!--                class="opensea_icon stroke_1"-->
              <!--                src="@/assets/images/opensea.svg"-->
              <!--                alt=""-->
              <!--                @click="viewNftInOpensea"-->
              <!--              />-->
              <!--            </div>-->
              <p class="cover_uuid">
                {{ card_unique_id ? card_unique_id : "" }}
              </p>
              <div>
                <img
                  class="opensea_icon stroke_1"
                  :src="chain_type ? getNetworkImage() : ''"
                  alt=""
                  @click="viewTransaction"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import NetworkConfig from "@/contract/NetworkConfig";
import getPeriod from "@/utils/getDateDifference";

const BASE_URL = process.env.VUE_APP_IMAGE_URL;
const BASE_URL_UPLOAD = process.env.VUE_APP_UPLOAD_URL;
const NETWORK_TYPE = process.env.VUE_APP_NETWORK_TYPE;
export default {
  props: [
    "id",
    "chest_card_image",
    "chest_card_text",
    "chest_look",
    "card_background",
    "card_unique_id",
    "chest_type",
    "chain_logo",
    "chain_name",
    "target_growth",
    "target_price",
    "current_growth",
    "investment_quantity",
    "uuid",
    "investment_cost",
    "bought_price",
    "current_price",
    "favorite",
    "withCustomisation",
    "gift",
    "before_opening",
    "create_variant",
    "disableShare",
    "txHash",
    "chain_type",
    "swap",
    "endTime",
    "startTime",
    "status",
    "openTimeX",
  ],
  data() {
    return {
      randomColorsForBackground: ["#65C7B3", "#FFF4AB", "#89D0E0", "#FFCBFF"],
      showPrompt: false,
      chest_favorite: null,
      opened: false,
      date_progress: "",
      share_bar: false,
      dateDifference: "",
      pricesForCuttingZero: {
        bought_price: 0,
        current_price: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      "logged",
      "imageFormat",
      "collectDataForChestStep1",
      "collectDataForChestStep2",
      "createData",
    ]),
  },
  methods: {
    ...mapActions(["isFloat", "getChest", "cutZero"]),
    setDateDifference() {
      const date = new Date();

      let startTimeDate,
        endTimeDate,
        replacedStartTimeDate,
        replacedEndTimeDate;

      if (this.startTime && this.startTime.toString().includes(" ")) {
        replacedStartTimeDate = this.startTime.toString().replace(/-/g, "/");
        startTimeDate = new Date(replacedStartTimeDate);
      } else {
        startTimeDate = new Date(this.startTime);
      }
      if (this.endTime && this.endTime.toString().includes(" ")) {
        replacedEndTimeDate = this.endTime.toString().replace(/-/g, "/");
        endTimeDate = new Date(replacedEndTimeDate);
      } else {
        endTimeDate = new Date(this.endTime);
      }

      if (date > endTimeDate) {
        this.dateDifference = getPeriod(startTimeDate, endTimeDate, false);
      } else {
        this.dateDifference = getPeriod(date, endTimeDate, false);
      }
    },
    getNetworkImage() {
      return require(`@/assets/images/${NetworkConfig[this.chain_type].image}`);
    },
    getImage(image) {
      return `${BASE_URL}/${image}.png`;
    },
    getBgImage(image) {
      return `${BASE_URL}/${image}`;
    },
    getUploadImage(image) {
      return `${BASE_URL_UPLOAD}/${image}`;
    },
    getRibbonImage() {
      let ribbonImage;

      const afterOpeningPurpleCondition = Number(this.openTimeX) >= 1.1;
      const afterOpeningRedCondition = Number(this.openTimeX) < 1.1;

      if (this.status === "opened") {
        ribbonImage = require("@/assets/images/yellow_ribbon.png");
      } else if (this.status === "opened_time" && afterOpeningPurpleCondition) {
        ribbonImage = require("@/assets/images/purple_ribbon.png");
      } else if (this.status === "opened_time" && afterOpeningRedCondition) {
        ribbonImage = require("@/assets/images/red_ribbon.png");
      }
      return ribbonImage;
    },
    getRibbonText() {
      let ribbonText;

      const afterOpeningPurpleCondition = Number(this.openTimeX) >= 1.1;
      const afterOpeningRedCondition = Number(this.openTimeX) < 1.1;

      if (this.status === "opened") {
        ribbonText = "Successed";
      } else if (this.status === "opened_time" && afterOpeningPurpleCondition) {
        ribbonText = "Partially";
      } else if (this.status === "opened_time" && afterOpeningRedCondition) {
        ribbonText = "Failed";
      }
      return ribbonText;
    },
    async shareHandler() {
      if (window.innerWidth >= 768 && this.share_bar === false) {
        this.$store.commit("OVERLAY_ON", true);
      } else {
        this.$store.commit("OVERLAY_ON", false);
      }
      this.share_bar = !this.share_bar;
    },
    closeShareBar() {
      if (this.share_bar) {
        this.share_bar = false;
        this.$store.commit("OVERLAY_ON", false);
      }
    },
    favoriteAddOrRemoveHandler() {
      if (!this.logged) {
        this.showPrompt = true;
        setTimeout(() => {
          this.showPrompt = false;
        }, 1000);
      } else {
        this.chest_favorite = !this.chest_favorite;
        this.$emit("favoriteHandler", {
          action_type: this.chest_favorite,
          id: this.id,
        });
      }
    },
    viewNftInOpensea() {
      const url =
        NetworkConfig[this.chain_type][NETWORK_TYPE].openseaNftBaseUrl +
        this.card_unique_id.split("#")[1];
      window.open(url, "_blank");
    },
    viewTransaction() {
      const url =
        NetworkConfig[this.chain_type][NETWORK_TYPE].blockExplorerTxBaseUrl +
        this.txHash;
      window.open(url, "_blank");
    },
    flipHandler(e) {
      if (
        e.target.classList[0] !== "icon_favorite" &&
        e.target.classList[0] !== "icon_share" &&
        e.target.classList[0] !== "polygon_icon" &&
        e.target.classList[0] !== "opensea_icon"
      ) {
        this.opened = !this.opened;
      }
    },

    getDateInterval() {
      if (this.before_opening) {
        const startTime = this.before_opening?.split("-")[0].split(" ")[0];
        const endTime = this.before_opening?.split("-")[1].split(" ")[0];
        return `${startTime}-${endTime}`;
      }
    },

    manipulationsWithStartAndEndDate() {
      if (this.before_opening) {
        const start_options = this.before_opening
          .split("-")[0]
          ?.split(" ")[0]
          .split(".");
        const end_options = this.before_opening
          .split("-")[1]
          ?.split(" ")[0]
          .split(".");

        const startHours = this.before_opening.split("-")[0]?.split(" ")[1];
        const endHours = this.before_opening.split("-")[1]?.split(" ")[1];

        let startDate, endDate;

        if (start_options) {
          const hours = startHours?.split(":");
          startDate = new Date(
            +start_options[0],
            +start_options[1] - 1,
            +start_options[2],
            +hours[0],
            +hours[1],
            +hours[2],
            0
          );
        }
        if (end_options) {
          const hours = endHours?.split(":");
          endDate = new Date(
            +end_options[0],
            +end_options[1] - 1,
            +end_options[2],
            +hours[0],
            +hours[1],
            +hours[2],
            0
          );
        }
        this.dateToPercent(startDate, endDate);
      }
    },
    getCurrentTimeInUTC0Timezone() {
      const now = new Date();
      const nowISOString = now.toISOString();

      const date = nowISOString.split("T")[0].split("-");
      const hours = nowISOString.split("T")[1].split(".")[0].split(":");

      const currentDateInUTC0Timezone = new Date(
        +date[0],
        +date[1] - 1,
        +date[2],
        +hours[0],
        +hours[1],
        +hours[2]
      );

      return currentDateInUTC0Timezone;
    },
    dateToPercent(startDate, endDate) {
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();
      const currentTime = new Date(
        this.getCurrentTimeInUTC0Timezone()
      ).getTime();

      // Calculate total duration in minutes
      const totalDuration = (endTime - startTime) / (1000 * 60);

      // Calculate elapsed duration in minutes
      const elapsedDuration = (currentTime - startTime) / (1000 * 60);

      // Calculate progress percentage
      let progressPercentage = (elapsedDuration / totalDuration) * 100;

      if (progressPercentage >= 100) {
        progressPercentage = 100;
      }

      this.date_progress = `${progressPercentage.toFixed(2)}%`;
    },
    async setPricesFromZero() {
      this.pricesForCuttingZero.bought_price = await this.cutZero({
        number: this.bought_price,
        afterDotCount: 10,
      });
      this.pricesForCuttingZero.current_price = await this.cutZero({
        number: this.current_price,
        afterDotCount: 10,
      });
    },
  },
  watch: {
    opened(val) {
      if (val) this.$refs.build_chest_look.classList.add("flip-in-diag-1-tr");
      else
        this.$refs.build_chest_look_hidden_variant.classList.add(
          "flip-in-diag-2-br"
        );
    },
    async bought_price() {
      await this.setPricesFromZero();
    },
    async current_price() {
      await this.setPricesFromZero();
    },
    endTime() {
      this.setDateDifference();
    },
    before_opening() {
      this.manipulationsWithStartAndEndDate();
    },
  },
  async created() {
    this.chest_favorite = this.favorite;
    await this.setPricesFromZero();
    this.manipulationsWithStartAndEndDate();
    this.setDateDifference();
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/sass/imports/main/variables";
@import "../../assets/sass/imports/main/mixins";
@import "../../assets/sass/imports/main/general";
@import "../../assets/sass/imports/main/extendsStart";
@import "../../assets/sass/imports/main/extendsEnd";

.chest_ribbon {
  transform: rotate(90deg);
  position: absolute;
  z-index: 99999;
  left: -6px;
  bottom: -6px;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  transition: 0.3s ease-in-out;

  p {
    color: white;
    font-family: $bold;
    font-size: 20px;
    transform: rotate(-45deg);
    display: flex;
    width: 100%;
    height: 44%;
    justify-content: center;
    align-items: flex-end;
  }
}

.wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;

  .share_popup {
    margin-bottom: 20px;
  }
}

.build_chest_look {
  width: 375px;
  height: 388px;
  border: 1px solid gray;
  border-radius: 12px;
  padding: 6px;
  box-shadow: 0px 2px 0px rgb(0 0 0 / 25%);
  transition: 0.8s ease-out both;
  position: relative;

  @include mediaRange($size320, $size768) {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    margin-bottom: -20px;
    margin-top: -20px;
    height: 395px;
  }

  .show_when_chest_gift {
    margin-top: -15px;
    transition: 0.3s ease-out;

    img {
      width: 44px;
    }
  }

  .selected_bg {
    height: 100%;
    padding: 7px;
    border-radius: 12px;
    background: url("@/assets/images/default_chest_bg.png");
    border-radius: 12px;
    -webkit-animation: zoom_out 0.3s ease-out both;
    animation: zoom_out 0.3s ease-out both;

    &:hover,
    &:active {
      -webkit-animation: zoom-top 0.3s ease-out both;
      animation: zoom-top 0.3s ease-out both;

      .chest_ribbon {
        left: 0px;
        bottom: 0px;
        transition: 0.3s ease-in-out;
      }

      .current_x {
        background: $yellowFF !important;

        &::before {
          border-color: $yellowFF transparent transparent transparent !important;
        }
      }

      .show_when_chest_gift {
        margin-top: -3px;
        transition: 0.2s ease-in-out;
      }

      cursor: pointer;
    }

    .first_row {
      display: flex;
      align-items: center;
      position: relative;
      height: 30px;

      .gemchest_logo {
        width: 48px;
        height: 48px;
        margin-right: 8px;
      }

      .token_params {
        display: flex;
        align-items: center;
        min-width: 75%;
        margin-top: 14px;
      }

      .share_and_favorite_icons {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-top: 20px;

        //.icon_favorite:hover {
        //  @include iconColorHover;
        //}

        .icon_share:hover,
        .icon_share:active {
          color: $blue58;
        }

        div {
          align-self: flex-end;
          margin-top: 6px;
          font-size: 24px;
          cursor: pointer;
          color: $white;
        }

        .favorite_prompt {
          animation: fade 5s linear;
          position: absolute;
          z-index: 999;
          width: 170px;
          right: 45px;
          top: 38px;
        }
      }

      .token_info_icon {
        width: 48px;
        height: 48px;
        background: $white;
        border-radius: 50%;
        border: 1px solid $black;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 90%;
          height: 90%;
          border-radius: 50%;
        }
      }

      .chest_type_text {
        font-family: $bold;
        font-size: 24px;
        line-height: 25px;
        color: $yellowFF;
        margin-left: 2px;
        margin-top: -1px;
        text-transform: capitalize;

        span {
          color: $white;
        }
      }

      .token_info_name {
        height: 24px;
        padding: 0 5px;
        width: fit-content;
        border: 1px solid $black;
        background: $purple84;
        border-radius: 7px;
        font-family: $bold;
        font-size: 16px;
        line-height: 30px;
        color: $white;
        display: flex;
        align-items: center;

        span {
          color: $yellowFF;
          margin: 0 4px;
        }
      }
    }

    .second_row,
    .cover_second_row {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin: 0 11px;
      margin-top: 21px;
      @include mediaRange($size320, $size768) {
        margin-top: 19px;
      }

      .build_chest {
        position: relative;
        width: 156px;
        height: 129px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 110%;
          filter: drop-shadow(0px 3px 7px white);
          -webkit-filter: drop-shadow(0px 3px 7px white);
        }

        .chest_lock_card_token_image {
          position: absolute;

          z-index: 11;
          background: #ffffff;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          margin-right: 37px;
          margin-top: 6px;
          justify-content: center;

          img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
          }
        }

        .chest_lock_card_token_cover_image {
          position: absolute;
          z-index: 11;
          background: #ffffff;
          width: 43px;
          height: 43px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          margin-right: 41px;
          margin-top: 7px;
          justify-content: center;

          img {
            width: 38px;
            height: 38px;
            border-radius: 50%;
          }
        }
      }

      .customization_comment_image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .upload_image {
          position: relative;
          z-index: 1;
          top: 4px;
          width: 130px;
          height: 90px;
          border: 1px solid #000000;
          border-radius: 6px;
          transform: rotate(4.55deg);
          padding: 5px;

          > div {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
          }
        }

        .chest_comment {
          position: relative;
          z-index: 0;
          resize: none;
          width: 160px;
          height: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 11px 10px;
          overflow: hidden;
          background: $grayF5;
          border: 1px solid $black;
          box-shadow: 0px 3.92988px 3.92988px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          transform: rotate(-2.51deg);
          font-family: $bold;
          font-size: 14px;
          line-height: 13px;
          text-align: center;
          color: $black;
        }
      }
    }

    .cover_second_row {
      margin-top: 60px;

      .build_chest {
        width: 156px;
        height: 129px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .customization_comment_image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .upload_image {
          position: relative;
          z-index: 1;
          top: 4px;
          width: 130px;
          height: 90px;
          border: 1px solid #000000;
          border-radius: 6px;
          transform: rotate(4.55deg);
          padding: 5px;

          > div {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
          }
        }

        .chest_comment {
          position: relative;
          z-index: 0;
          resize: none;
          width: 160px;
          height: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px 10px;
          overflow: hidden;
          background: $grayF5;
          border: 1px solid $black;
          box-shadow: 0px 3.92988px 3.92988px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          transform: rotate(-2.51deg);
          font-family: $bold;
          font-size: 14px;
          line-height: 13px;
          text-align: center;
          word-break: break-word;
          color: $black;
        }
      }
    }

    .third_row,
    .cover_third_row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .chest_unique_id {
        font-family: $bold;
        font-size: 12px;
        line-height: 16px;
        color: $white;
      }

      .chest_claim_await_time {
        position: relative;

        .oclock_image {
          position: absolute;
          width: 22px;
          height: 23px;
          z-index: 24;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .show_await_time {
          position: relative;
          -webkit-transform: translateZ(0);
          transform: translateZ(0);
          z-index: 22;
          height: 23px;
          width: 165px;
          background: $purple66;
          border: 1px solid $black;
          border-radius: 12px;
          margin-left: 2px;

          .s_e_date {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            position: absolute;
            z-index: 14;
            width: 156px;
            height: 100%;
            color: #ffffff;
            font-family: "kanitbold";
            font-size: 14px;
            line-height: 21px;
            left: 0px;
            right: 0;
            margin: 0 auto;
            text-align: center;
          }

          .await_date {
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            position: absolute;
            z-index: 12;
            top: 0;
            left: 0;
            height: 100%;
            border-radius: 14px;
            padding-left: 25px;
            background: $purpleAD;
            align-self: flex-start;
          }
        }

        .nft_version_show_await_time {
          width: 182px;
          .s_e_date {
            left: 14px;
          }
        }
      }
    }

    .last_row_wrapper {
      display: flex;
      justify-content: flex-end;
    }

    .last_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 3px;
      width: 53%;
    }

    .cover_uuid {
      color: $white;
      font-size: 15px;
      font-family: $bold;
      margin-top: 10px;
    }

    .opensea_icon {
      width: 30px;
      height: 30px;
    }

    .cover_third_row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      margin-top: 52px;
      width: 65%;

      .chest_claim_await_time {
        transform: scale(1.3);
        -webkit-transform: scale(1.3);
        transform-origin: center center;
        -webkit-transform-origin: center center;
        margin-top: 7px;
      }

      .show_x_progress {
        width: 165px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2px;
        transform: scale(1.3);
        -webkit-transform: scale(1.3);
        transform-origin: center center;
        -webkit-transform-origin: center center;
        margin-top: -5px;

        .start_x,
        .end_x {
          font-family: $bold;
          font-size: 18px;
          color: $white;
          line-height: 20px;
          filter: drop-shadow(0px 2px 0px $black);
          -webkit-filter: drop-shadow(0px 2px 0px $black);
        }

        .current_x {
          position: absolute;
          min-width: 30px;
          max-width: 62px;
          padding: 2px 4px 5px;
          left: 0;
          right: 0;
          margin: 2px auto;
          background: $blue58;
          text-align: center;
          filter: drop-shadow(0px 2px 0px #000);
          -webkit-filter: drop-shadow(0px 2px 0px #000);
          border: 1px solid black;
          bottom: 2px;
          border-radius: 4px;
          z-index: 9999;
          -webkit-transform: translateZ(0);
          transform: translateZ(0);

          &::before {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            bottom: -4px;
            left: calc(50% - 5px);
            z-index: 9999;
            border-style: solid;
            border-width: 7px 5px 0 5px;
            border-color: $blue58 transparent transparent transparent;
          }

          p {
            font-family: $bold;
            font-size: 18px;
            color: $white;
            filter: drop-shadow(0px 3px 0px $black);
            -webkit-filter: drop-shadow(0px 3px 0px $black);
          }
        }
      }
    }

    .investment_row,
    .targets_row,
    .current_state_row {
      width: 100%;
      height: 41px;
      margin-top: 7px;
      display: flex;
      align-items: center;
      background: $grayF5;
      border-radius: 6px;
      border: 1px solid $gray69;
      @include mediaRange($size320, $size768) {
        height: 44px;
      }

      .investment_text,
      .targets_text,
      .current_state_text {
        width: 110px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: $orangeF4;
        border-right: 1px solid $gray69;
        border-radius: 5px 0px 0px 5px;
        font-family: $bold;
        font-size: 14px;
        text-align: center;
        line-height: 18px;
        color: $white;
        @include mediaRange($size320, $size768) {
          font-size: 17px;
          line-height: 20px;
        }
      }

      .targets_text {
        background: $green5A;
      }

      .current_state_text {
        background: $blue58;
      }

      .investment_options {
        .quantity,
        .cost,
        .bought_price {
          display: flex;
          align-items: center;
          margin-left: 5px;

          .title {
            width: 75px;
            text-align: right;
            font-family: $bold;
            font-size: 12px;
            line-height: 16px;
            color: $white;
            margin-right: 10px;
            @include mediaRange($size320, $size768) {
              font-size: 16px;
              line-height: 20px;
            }

            span {
              margin-left: 7px;
            }
          }

          .value {
            font-family: $bold;
            font-size: 14px;
            line-height: 16px;
            text-align: right;
            color: $yellowFE;
            @include mediaRange($size320, $size768) {
              font-size: 16px;
              line-height: 18px;
            }

            span {
              color: #d7effe;
              margin-right: 4px;
            }
          }
        }
      }
    }

    .targets_row {
      margin-bottom: 7px;

      .targets_options {
        .cost,
        .bought_price {
          display: flex;
          align-items: center;
          margin-left: 5px;

          .title {
            width: 75px;
            text-align: right;
            font-family: $bold;
            font-size: 12px;
            line-height: 16px;
            color: $white;
            margin-right: 10px;
            @include mediaRange($size320, $size768) {
              font-size: 16px;
              line-height: 18px;
            }

            span {
              margin-left: 7px;
            }
          }

          .value {
            font-family: $bold;
            font-size: 14px;
            line-height: 16px;
            text-align: right;
            color: $yellowFE;
            @include mediaRange($size320, $size768) {
              font-size: 16px;
              line-height: 18px;
            }

            span {
              color: #d7effe;
              margin-right: 4px;
            }
          }
        }
      }
    }

    .current_state_row {
      margin-bottom: 7px;

      .current_state_options {
        .cost,
        .bought_price {
          display: flex;
          align-items: center;
          margin-left: 5px;

          .title {
            width: 75px;
            text-align: right;
            font-family: $bold;
            font-size: 12px;
            line-height: 16px;
            color: $white;
            margin-right: 10px;
            @include mediaRange($size320, $size768) {
              font-size: 16px;
              line-height: 18px;
            }

            span {
              margin-left: 7px;
            }
          }

          .value {
            font-family: $bold;
            font-size: 14px;
            line-height: 16px;
            text-align: right;
            color: $yellowFE;
            @include mediaRange($size320, $size768) {
              font-size: 16px;
              line-height: 18px;
            }

            span {
              color: #d7effe;
              margin-right: 4px;
            }
          }
        }
      }
    }
  }

  .cover_chest_type_text {
    margin-bottom: 10px;
    margin-top: 10px !important;
    text-transform: capitalize;
  }

  .polygon_icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-self: end;
    margin-top: -2px;
  }
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  5%,
  95% {
    opacity: 1;
  }
}

@-webkit-keyframes zoom-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-transform: scale(1.04) translateY(0px);
    transform: scale(1.04) translateY(0px);
    box-shadow: 0 0 22px 2px rgba(255, 255, 255, 1);
    transition: 0.8s ease-out both;
  }
}

@keyframes zoom-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-transform: scale(1.04) translateY(0px);
    transform: scale(1.04) translateY(0px);
    box-shadow: 0 0 22px 2px rgba(255, 255, 255, 1);
    transition: 0.8s ease-out both;
  }
}

@keyframes zoom_out {
  0% {
    -webkit-transform: scale(1.04) translateY(-7px);
    transform: scale(1.04) translateY(-7px);
    -webkit-transform-origin: top;
    transform-origin: top;
    box-shadow: 0 0 22px 2px rgba(255, 255, 255, 1);
    transition: 0.8s ease-out both;
  }
  100% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  }
}

.flip-in-diag-1-tr {
  -webkit-animation: flip-in-diag-1-tr 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: flip-in-diag-1-tr 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes flip-in-diag-1-tr {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, -80deg);
    transform: rotate3d(1, 1, 0, -80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
    transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
}

@keyframes flip-in-diag-1-tr {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, -80deg);
    transform: rotate3d(1, 1, 0, -80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
    transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
}

.flip-in-diag-2-br {
  -webkit-animation: flip-in-diag-2-br 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: flip-in-diag-2-br 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes flip-in-diag-2-br {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, -80deg);
    transform: rotate3d(-1, 1, 0, -80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
    transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
}

@keyframes flip-in-diag-2-br {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, -80deg);
    transform: rotate3d(-1, 1, 0, -80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
    transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
}

.showShare {
  position: relative;
  z-index: 999999;
}
</style>
