import abis from "@/contract/ContractConfig/abis";

const NETWORK_TYPE = process.env.VUE_APP_NETWORK_TYPE;
const addressPolygon = process.env.VUE_APP_CONTRACT_ADDRESS_MATIC;
const addressEthereum = process.env.VUE_APP_CONTRACT_ADDRESS_ETH;
const addressArbitrum = process.env.VUE_APP_CONTRACT_ADDRESS_ARBITRUM;

const contractConfig = {
  polygon: {
    testnet: {
      abi: abis[NETWORK_TYPE].polAbi,
      address: addressPolygon,
    },
    mainnet: {
      abi: abis[NETWORK_TYPE].polAbi,
      address: addressPolygon,
    },
  },
  ethereum: {
    testnet: {
      abi: abis[NETWORK_TYPE].ethAbi,
      address: addressEthereum,
    },
    mainnet: {
      abi: abis[NETWORK_TYPE].ethAbi,
      address: addressEthereum,
    },
  },
  arbitrum: {
    mainnet: {
      abi: abis[NETWORK_TYPE].arbitrumAbi,
      address: addressArbitrum,
    },
  },
};

export default contractConfig;
