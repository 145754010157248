<template>
  <div class="header" ref="header"
       :class="current_tab_mobile_navigation === 'gemchest' || (current_tab_mobile_navigation === 'create_chest' &&  mobile_generating_proccess) ? 'mobile_header_home_page' : 'off_header'">
    <div class="page_container header_container">
      <div class="header_items">
        <div class="header_left_side">
          <ul>
            <li>
              <a href="/" class="header_logo" @click="router.go('/')">
                <img src="@/assets/images/header_logo_for_mobile.png" alt="logo" class=""/>
              </a>
            </li>
          </ul>
          <HeaderTabs class="header_tabs" v-if="window_width >= 768"/>
        </div>
        <div v-if="window_width >= 768" class="header_parts_wrapper">
          <HeaderLoginPart />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import HeaderLoginPart from "@/components/Header/HeaderLoginPart.vue";
import HeaderTabs from "@/components/Header/HeaderTabs.vue";

export default {
  components: {HeaderTabs, HeaderLoginPart},
  data() {
    return {
      address: "",
      lang: localStorage.getItem('locale_') || 'en',
      window_width: 0,
      current_network: localStorage.getItem('current_network') || 'polygon',
    };
  },
  computed: {
    ...mapGetters(["WalletAccount", "mobile_generating_proccess", "openNotification", 'signatureMessage', "signature", "logged", "networks", "notifications", "notifications_count", "current_tab_mobile_navigation"]),
  },
  methods: {
    ...mapActions(['loginMetamask', "getNonce", "sendSignature", "updateNotifications"]),
    changeLanguageHandler() {
      if (this.lang === 'en') {
        this.lang = 'ru';
        localStorage.setItem('locale_', this.lang);
      } else {
        this.lang = 'en';
        localStorage.setItem('locale_', this.lang);
      }
      this.$router.go('/');
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.window_width = window.innerWidth;
    });
  },
  unmounted() {
    window.removeEventListener('resize', () => {
      this.window_width = window.innerWidth;
    });
  },
  created() {
    this.window_width = window.innerWidth;
  }
};
</script>
<style lang="scss">
@import "@/assets/sass/imports/main/variables";
@import "@/assets/sass/imports/main/mixins";
@import "@/assets/sass/imports/main/general";
@import "@/assets/sass/imports/main/extendsStart";
@import "@/assets/sass/imports/main/extendsEnd";

.header {
  background: #7a6de6;
  padding: 15px 0;
  border-top: 20px solid $purpleBA;

  .header_container {
    position: relative;
    @include mediaRange($size320, $size768) {
      zoom: 70%;
    }

  }

  .header_items {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.why_gemchest {
  margin-left: 150px;
}

.why_gemchest, .roadmap {
  text-align: center;
  width: 170px;
}

.header_left_side {
  @include mediaTo($size768) {
    width: 100%;
  }

  ul {
    @include mediaTo($size768) {
      width: 100%;
    }
    display: flex;
    align-items: center;
  }

  li {
    list-style: none;
    @include mediaTo($size768) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > a {

      cursor: pointer;
    }

    .header_logo {
      width: 120px;
      margin-top: 4px;
      position: absolute;
      height: 70px;
      top: -40px;

      img {
        width: 120px;
        margin-top: 7px;
        @include mediaRange($size320, $size768) {
          margin-top: 0;
        }
      }
    }
  }

  a {
    font-family: $bold;
    text-decoration: none;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    transition: .2s ease-in-out;

    &:hover, &:active {
      font-size: 22px;
      transition: .2s ease-in-out;
    }
  }
}

.header_right_side {
  display: flex;
  align-items: center;

  .header_account_info {
    display: flex;
    align-items: center;


    .header_avatar {
      max-width: 46px;
      max-height: 48px;
      margin: 0 40px 0 20px;
      cursor: pointer;
    }
  }

  .change_language {
    width: 71px;
    padding: 8px 0;
    text-align: center;
    background: #58b6ef;
    border: 1px solid $black;
    border-radius: 8px;
    font-family: $bold;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    cursor: pointer;
    margin-left: 20px;
  }

  button {
    width: 215px;
    background: $orangeF4;
    border: 1px solid black;
    border-radius: 6px;
    padding: 8px 0;
    font-family: $bold;
    font-size: 20px;
    line-height: 30px;
    color: $white;
    filter: drop-shadow(0px 4px 0px $black);
    -webkit-filter: drop-shadow(0px 4px 0px $black);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mediaRange($size320, $size768) {
      width: 160px;
    }

    img {
      margin-left: 10px;
      width: 30px;
    }

    &:hover, &:active {
      font-size: 22px;
      transition: .2s ease-in-out;
      @include siteBgHover;
    }
  }
}

.mobile_header_home_page {
  @include mediaRange($size320, $size768) {
    position: relative;
    z-index: 1;
  }
}

.off_header {
  @include mediaRange($size320, $size768) {
    display: none;
  }
}


.header_parts_wrapper {
  display: flex;
  align-items: center;

  .header_social_media_part {
    margin-right: 50px;
  }
}

.header_tabs {
  @include mediaFrom($size1024) {
    margin-left: 180px;
  }
  @include mediaRange($size768, $size1024) {
    margin-left: 130px;
  }
}

</style>
