export default {
    polygon: {
        image: 'polygon.png',
        defaultToken: 'MATIC',
        testnet: {
            key: 'maticmum',
            blockExplorerTxBaseUrl: 'https://mumbai.polygonscan.com/tx/',
            openseaNftBaseUrl: 'https://testnets.opensea.io/assets/mumbai/0x61d704a4d002D83f226D2107bA28D3E87DF9359E/',
        },
        mainnet: {
            key: 'matic',
            blockExplorerTxBaseUrl: 'https://polygonscan.com/tx/',
            openseaNftBaseUrl: 'https://opensea.io/assets/matic/0x75eabDE6A42FF57E4DA7703FedA7756c510b954C/',
        },
    },
}
