<template>
  <div class="message_popup">
    <div class="fail_image" v-if="message_popup_data.isFailed">
      <img src="@/assets/images/fail_images.png" alt="">
    </div>
    <div class="message">
      {{ message_popup_data.message }}
    </div>
    <div>
      <NextButton type="next-button-active" @submit="closeMessagePopupHandler">Close</NextButton>
    </div>
  </div>
  <div class="message_popup_overlay"></div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "MessagePopup",
  computed: {
    ...mapGetters(['message_popup_data']),
  },
  methods: {
    closeMessagePopupHandler() {
      this.$store.dispatch('clearMessagePopup');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/imports/main/_variables.scss";
@import "@/assets/sass/imports/main/_mixins.scss";
@import "@/assets/sass/imports/main/_general.scss";
@import "@/assets/sass/imports/main/_extendsStart.scss";
@import "@/assets/sass/imports/main/_extendsEnd.scss";


.message_popup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.message_popup {
  background: $white;
  border-radius: 14px;
  width: 350px;
  position: fixed;
  top: 25%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 3;
  padding: 10px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  @include mediaTo($size768) {
    width: 280px;
  }

  .fail_image {
    img {
      width: 84px;
      @include mediaTo($size768) {
        width: 64px;
      }
    }
  }

  .message {
    font-family: $medium;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.075em;
    color: $black;
    margin-bottom: 15px;
    @include mediaTo($size768) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .next-button-active {
    border: 1px solid $black;
    font-size: 16px;
    padding: 0 10px;
  }
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}


</style>