<template>
  <div class="header_notification"  ref="notification" @click="notificationHandler">
    <div class="notification_icon stroke_1" :class="withBorder ? 'with_border' : '' ">
      i
    </div>
    <NotificationCount class="notification_count"  />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NotificationCount from "@/components/UI/Notification/NotificationCount.vue";

export default {
  components: {NotificationCount},
  props: {
    withBorder: {
      type: Boolean,
      required: true
    }
  },
  name: "NotificationButton",
  data() {
    return {
      window_width: 0,
    }
  },
  computed: {
    ...mapGetters(["openNotification", "notifications_count", "notifications"]),
  },
  methods: {
    ...mapActions(["getNotifications", "updateNotifications"]),
    async notificationHandler() {
      this.$store.commit('SET_OPEN_NOTIFICATION', !this.openNotification);
      if (this.openNotification && this.notifications_count) {
        await this.updateNotifications(localStorage.getItem('account'));
      }
    },
    async showNotifications() {
      const token = localStorage.getItem('token') || null;
      if (token) {
        await this.getNotifications({offset: 0});
      }
    }
  },
  watch: {
    openNotification(val) {
      if (val && window.innerWidth > 768) {
        this.$refs.notification.style.zIndex = 99999999;
      } else {
        this.$refs.notification.style.zIndex = 2;
      }
    }
  },
  async created() {
    await this.showNotifications();
    this.window_width = window.innerWidth;
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/imports/main/_variables.scss";
@import "@/assets/sass/imports/main/_mixins.scss";
@import "@/assets/sass/imports/main/_general.scss";
@import "@/assets/sass/imports/main/_extendsStart.scss";
@import "@/assets/sass/imports/main/_extendsEnd.scss";

.header_notification {
  position: relative;
  z-index: 2;
  display: flex;
  cursor: pointer;
  @include mediaRange($size320, $size768) {
    z-index: 2;
  }
  .with_border {
    border: 1px solid black;
    filter: drop-shadow(0px 4px 0px $black);
    -webkit-filter: drop-shadow(0px 4px 0px $black)
  }
  .notification_icon {
    width: 33px;
    height: 30px;
    text-align: center;
    background: $orangeF4;
    border-radius: 6px;
    font-family: $bold;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    cursor: pointer;
  }

  .notification_count {
    position: absolute;
    bottom: 25px;
    left: 20px;
    min-width: 30px;
    height: 25px;
    text-align: center;
    background: red;
    border: 3px solid #fa5050;
    border-radius: 6px;
    font-family: $bold;
    font-size: 15px;
    line-height: 20px;
    color: #ffffff;
    filter: drop-shadow(0px 4px 0px #a10000);
    -webkit-filter: drop-shadow(0px 4px 0px #a10000);
    cursor: pointer;
  }
}
</style>
