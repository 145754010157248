export default {
    oeth: {
        chainId: `0x${Number(10).toString(16)}`,
        chainName: "Optimism",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18,
        },
        rpcUrls: [
            "https://mainnet.optimism.io/"
        ],
        blockExplorerUrls: ["https://optimistic.etherscan.io"],
    },
}
