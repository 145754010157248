import { createApp } from "vue";
// components
import NextButton from "@/components/UI/NextButton.vue";
import CustomCheckbox from "@/components/Checkbox/CustomCheckbox.vue";
import ChestLockCard from "@/components/UI/ChestLockCard";
import CustomLoader from "@/components/UI/CustomLoader";
import InputPrompt from "@/components/UI/InputPrompt.vue";
import SharePopup from "@/components/SharePopup/SharePopup.vue";

// helpers
import setGlobalVariables from "@/globalVariables/setGlobalVariables";

// plugins
import "@/assets/sass/main.scss";
import "@/plugins/axios";
import VueClickAway from "vue3-click-away";
import VueSocialSharing from "vue-social-sharing";
import VueLazyload from "vue-lazyload";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

// vue
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";

const app = createApp(App);

setGlobalVariables(app);
app
  .component("NextButton", NextButton)
  .component("CustomCheckbox", CustomCheckbox)
  .component("ChestLockCard", ChestLockCard)
  .component("CustomLoader", CustomLoader)
  .component("InputPrompt", InputPrompt)
  .component("SharePopup", SharePopup)
  .component("Datepicker", VueDatePicker);
app
  .use(VueClickAway)
  .use(VueSocialSharing)
  .use(VueLazyload)
  .use(i18n)
  .use(store)
  .use(router)
  .mount("#app");
