export default {
    ogor: {
        chainId: `0x${Number(420).toString(16)}`,
        chainName: "Optimism Goerli Testnet",
        nativeCurrency: {
            name: "Ether",
            symbol: "ETH",
            decimals: 18,
        },
        rpcUrls: [
            "https://goerli.optimism.io/"
        ],
        blockExplorerUrls: ["https://goerli-optimism.etherscan.io/"],
    },
}
