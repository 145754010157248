export default {
    optimism: {
        image: 'optimism.png',
        defaultToken: 'OP',
        testnet: {
            key: 'ogor',
            blockExplorerTxBaseUrl: 'https://goerli-optimism.etherscan.io/tx/',
            openseaNftBaseUrl: 'https://testnets.opensea.io/assets/goerli/0x99e114a6BB173B9B589786ceAeBF9ceF7276Eaae/',
        },
        mainnet: {
            key: 'oeth',
            blockExplorerTxBaseUrl: 'https://optimistic.etherscan.io/tx/',
            openseaNftBaseUrl: 'https://opensea.io/assets',
        },
    },
}
