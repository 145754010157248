<template>
    <div class="header_right_side">
        <div v-if="logged" class="header_account_info">
            <div class="header_account_info">
                <div class="wrapp_notification_block">
                    <NotificationButton :withBorder="true"/>
                </div>
                <!--              <router-link to="/account">-->
                <!--                <img-->
                <!--                    src="@/assets/images/header_user_Avatar.svg"-->
                <!--                    alt=""-->
                <!--                    class="header_avatar"-->
                <!--                />-->
                <!--              </router-link>-->
            </div>
            <button class="stroke_2" @click="disconnectMetamask">
                Log out
                <img src="@/assets/images/metamask.png" alt="#"/>
            </button>
        </div>
        <button class="stroke_2" @click="connectMetamask" v-else>
            {{ $t("connect_wallet") }}
            <img src="@/assets/images/metamask.png" alt="#"/>
        </button>
<!--        <div class="stroke_1 change_language" @click="changeLanguage">{{ lang === 'en' ? 'En' : 'Ру' }}</div>-->
    </div>
</template>

<script>
import {Wallet} from "@/contract/Contract";
import {mapActions, mapGetters} from "vuex";
import router from "@/router";
import NetworkConfig from "@/contract/NetworkConfig";
import NotificationButton from "@/components/UI/Notification/NotificationButton.vue";

const NETWORK_TYPE = process.env.VUE_APP_NETWORK_TYPE;
export default {
    name: "HeaderLoginPart",
    components: {NotificationButton},
    data() {
        return {
            address: "",
            lang: localStorage.getItem("locale_") || "en",
            window_width: 0,
            current_network: localStorage.getItem("current_network") || "polygon",
        };
    },
    computed: {
        ...mapGetters([
            "WalletAccount",
            "current_tab_mobile_navigation",
            "openNotification",
            "signatureMessage",
            "signature",
            "logged",
            "networks",
            "notifications",
            "notifications_count",
            "current_tab_mobile_navigation",
        ]),
    },
    methods: {
        ...mapActions([
            "loginMetamask",
            "getNonce",
            "sendSignature",
            "loadingOff",
            "loadingOn",
        ]),
        changeLanguage() {
            localStorage.setItem('locale_', this.lang === 'en' ? 'ru' : 'en')
            this.$router.go();
        },
        async addOrSwitchNetwork() {
            const ntw = JSON.parse(
                JSON.stringify(
                    this.networks[NetworkConfig[this.current_network][NETWORK_TYPE].key]
                )
            );
            const current_metamask_ntw = window.ethereum
                ? `0x${Number(window.ethereum.networkVersion).toString(16)}`
                : "";

            if (window.ethereum && ntw.chainId !== current_metamask_ntw) {
                try {
                    await Wallet.switchNetwork(ntw.chainId);
                } catch (switchError) {
                    if (switchError.code === 4902 || switchError.code === -32603) {
                        try {
                            await Wallet.changeNetwork(ntw);
                        } catch (addError) {
                            this.$store.dispatch("addMessagePopupData", {
                                isFailed: true,
                                message: "Failed to change network",
                            });
                            return addError;
                        }
                    } else {
                        this.$store.dispatch("addMessagePopupData", {
                            isFailed: true,
                            message: "Failed to change network",
                        });
                    }
                }
            }
        },

        async connectMetamask() {
            if (window.ethereum) {
                this.$store.commit("OVERLAY_ON", true);
                await Wallet.connectMetamask()
                    .then((res) => {
                        this.address = res;
                        const loginParams = {
                            address: this.address,
                        };
                        this.$store.commit("SET_ACCOUNT", this.address);
                        this.loginMetamask(loginParams)
                            .then(() => {
                                this.addOrSwitchNetwork()
                                    .then((res) => {
                                        if (res && res.code === 4001) {
                                            this.$store.commit("OVERLAY_ON", false);
                                            return;
                                        }
                                        this.getNonce(loginParams)
                                            .then(() => {
                                                Wallet.signMessage(
                                                    `I am signing my one-time nonce: ${this.signatureMessage}`
                                                )
                                                    .then((res) => {
                                                        this.$store.commit("SET_SIGNATURE", res);
                                                        const sendSignatureParams = {
                                                            address: this.address,
                                                            signature: this.signature,
                                                        };
                                                        this.sendSignature(sendSignatureParams)
                                                            .then(() => {
                                                                this.$store.commit("SET_LOGIN", true);
                                                                this.$store.commit("OVERLAY_ON", false);
                                                            })
                                                            .catch(() => {
                                                                this.$store.dispatch("addMessagePopupData", {
                                                                    isFailed: true,
                                                                    message:
                                                                    this.$errorMessages.connectWalletFailed,
                                                                });
                                                                this.$store.commit("OVERLAY_ON", false);
                                                            });
                                                    })
                                                    .catch(() => {
                                                        this.$store.dispatch("addMessagePopupData", {
                                                            isFailed: true,
                                                            message: this.$errorMessages.connectWalletFailed,
                                                        });
                                                        this.$store.commit("OVERLAY_ON", false);
                                                    });
                                            })
                                            .catch(() => {
                                                this.$store.dispatch("addMessagePopupData", {
                                                    isFailed: true,
                                                    message: this.$errorMessages.connectWalletFailed,
                                                });
                                                this.$store.commit("OVERLAY_ON", false);
                                            });
                                    })
                                    .catch(() => {
                                        this.$store.dispatch("addMessagePopupData", {
                                            isFailed: true,
                                            message: "Please change your network",
                                        });
                                        this.$store.commit("OVERLAY_ON", false);
                                    });
                            })
                            .catch(() => {
                                this.$store.dispatch("addMessagePopupData", {
                                    isFailed: true,
                                    message: this.$errorMessages.connectWalletFailed,
                                });
                                this.$store.commit("OVERLAY_ON", false);
                            });
                    })
                    .catch(() => {
                        this.$store.dispatch("addMessagePopupData", {
                            isFailed: true,
                            message: this.$errorMessages.connectWalletFailed,
                        });
                        this.$store.commit("OVERLAY_ON", false);
                    });
            } else {
                window.open("https://www.metamask.io", "_blank");
            }
        },
        async disconnectMetamask() {
            this.$store.commit("SET_ACCOUNT", "");
            this.$store.commit("SET_LOGIN", false);
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("account");
            sessionStorage.clear();
            router.go("/");
        },
    },

    async created() {
        this.window_width = window.innerWidth;
        if (
            this.window_width < 768 &&
            this.current_tab_mobile_navigation == "gemchest"
        ) {
            this.loadingOn();
            setTimeout(() => {
                this.loadingOff();
            }, 1000);
        }
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/imports/main/variables";
@import "@/assets/sass/imports/main/mixins";
@import "@/assets/sass/imports/main/general";
@import "@/assets/sass/imports/main/extendsStart";
@import "@/assets/sass/imports/main/extendsEnd";

.header_right_side {
  display: flex;
  align-items: center;
  @include mediaRange($size320, $size768) {
    zoom: 80%;
  }

  .header_account_info {
    display: flex;
    align-items: center;

    .header_avatar {
      max-width: 46px;
      max-height: 48px;
      margin: 0 40px 0 20px;
      cursor: pointer;
    }
  }

  .change_language {
    width: 71px;
    padding: 8px 0;
    text-align: center;
    background: #58b6ef;
    border: 1px solid $black;
    border-radius: 8px;
    font-family: $bold;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    cursor: pointer;
    margin-left: 20px;
  }

  button {
    width: 130px;
    background: $orangeF4;
    border: 1px solid black;
    border-radius: 6px;
    padding: 8px 0;
    font-family: $bold;
    font-size: 20px;
    line-height: 30px;
    color: $white;
    filter: drop-shadow(0px 4px 0px $black);
    -webkit-filter: drop-shadow(0px 4px 0px $black);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mediaRange($size320, $size768) {
      width: 160px;
    }

    img {
      margin-left: 10px;
      width: 30px;
    }

    &:hover,
    &:active {
      font-size: 22px;
      transition: 0.2s ease-in-out;
      @include siteBgHover;
    }
  }
}

.wrapp_notification_block {
  margin-right: 30px;

  @include mediaTo($size768) {
    display: none;
  }
}

.mobile_header_home_page {
  @include mediaRange($size320, $size768) {
    position: relative;
    z-index: 1;
  }
}
</style>
