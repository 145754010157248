import testnetPolAbi from "@/contract/Abis/polygon/abi.json";
import testnetEthAbi from "@/contract/Abis/ethereum/ethAbi.json";
import mainnetPolAbi from "@/contract/Abis/polygon/polyginMainnetAbi.json";
import mainnetArbitrumAbi from "@/contract/Abis/arbitrum/arbitrumMainnetAbi.json";
export default {
  testnet: {
    polAbi: testnetPolAbi,
    ethAbi: testnetEthAbi,
  },
  mainnet: {
    polAbi: mainnetPolAbi,
    arbitrumAbi: mainnetArbitrumAbi,
  },
};
