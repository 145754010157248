<template>
    <div class="header_tab_list">
        <div
                v-for="tab in headerTabs"
                :key="tab.key"
                class="stroke_1"
                :style="
        tab.disabled ? 'background: #d9d9d9' : `background: ${tab.bgColor}`
      "
                @click="!tab.disabled ? handlerTabChange(tab) : ''"
        >
            <p>
                {{ tab.disabled ? `${tab.name} (Soon)` : tab.name }}
            </p>
            <img v-if="tab.img" :src="getImage(tab.img)" :alt="tab.name"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeaderTabs",
    data() {
        return {
            headerTabs: [
                {
                    name: "Tips",
                    key: "tips",
                    bgColor: "#ea88de",
                    img: "tips_icon.png",
                    disabled: false,
                },
                {
                    name: "Trends",
                    key: "trends",
                    bgColor: "#85c7ff",
                    img: "trends_icon.png",
                    disabled: false,
                },
                {
                    name: "Token",
                    key: "token",
                    bgColor: "#90e08a",
                    disabled: true,
                },
            ],
        };
    },
    methods: {
        handlerTabChange(tab) {
            this.$store.commit("SET_CURRENT_HEADER_TAB", tab);
        },
        getImage(image) {
            return require(`@/assets/images/${image}`);
        },
    },
    created() {
        this.handlerTabChange(this.headerTabs[0])
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/imports/main/variables";
@import "@/assets/sass/imports/main/mixins";
@import "@/assets/sass/imports/main/general";
@import "@/assets/sass/imports/main/extendsStart";
@import "@/assets/sass/imports/main/extendsEnd";

.header_tab_list {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: -36px;

  @include mediaRange($size768, $size1200) {
    width: 94%;
  }

  div {
    color: $white;
    font-size: 18px;
    line-height: 22px;
    font-family: $bold;
    padding: 9px;
    height: 42px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    border: 1px solid $black;
    border-bottom: none;
    cursor: pointer;

    @include mediaFrom($size1024) {
      flex: 0 0 44%;
    }
    @include mediaRange($size768, $size1024) {
      flex: 0 0 33%;
    }
    @include mediaTo($size768) {
      flex: 0 0 33.3333%;
      font-size: 16px;
    }

    img {
      width: 30px;
      margin-left: 10px;
    }
  }
}
</style>
