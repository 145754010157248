<template>
  <div class="mobile_navigation">
    <div class="mobile_view_navigation_links">
      <div v-for="(item,index) in navigation_list" :key="item.id"
           :class="index !== 0 && index !== navigation_list.length - 1 ? 'center_nav_link' : ''"
           class="mobile_view_navigation_button stroke_1"
           @click="changeTab(item.key)"
           :style="selectedTab !== item.key ? `` : '-webkit-box-shadow: inset -1px -1px 32px -6.5px #548eb2;-moz-box-shadow: inset -1px -1px 32px -6.5px #548eb2;box-shadow: inset -1px -1px 32px -6.5px #548eb2;background-color: #58b6ef;'">
        <p>
          {{ item.name }}
        </p>
        <NotificationCount class="mobile_nav_notification_count" @notificationHandler="notificationHandler"
                           v-if="item.key === 'chests' && selectedTab !== 'chests'"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NotificationCount from "@/components/UI/Notification/NotificationCount.vue";

export default {
  name: "MobileNavigation",
  components: {NotificationCount},
  computed: {
    ...mapGetters(['current_tab_mobile_navigation', 'openNotification', 'notifications_count']),
  },
  data() {
    return {
      selectedTab: "create_chest",
      navigation_list: [

        {
          id: 1,
          name: "Chests",
          key: "chests",

        },
        {
          id: 2,
          name: "Create Chest",
          key: "create_chest",

        },
        {
          id: 3,
          name: "GemChest",
          key: "gemchest",
        },
      ],
    }
  },
  methods: {
    ...mapActions(['loadingOff', 'updateNotifications']),
    changeTab(key) {
      if (this.$route.name !== 'create_chest') {
        this.$router.push('/')
      }
      this.selectedTab = key;
      this.$store.commit('SET_MOBILE_NAVIGATION_TAB', key);
      sessionStorage.setItem('current_tab_mobile_navigation', key);
    },
    navigationCreateFunctionality() {
      if (this.$router.options.history.location == '/' && !this.current_tab_mobile_navigation) {
        this.selectedTab = 'create_chest';
        this.$store.commit('SET_MOBILE_NAVIGATION_TAB', this.selectedTab)
      } else {
        this.selectedTab = this.current_tab_mobile_navigation;
        this.$store.commit('SET_MOBILE_NAVIGATION_TAB', this.selectedTab)
      }
      if (this.$router.options.history.location.length >= 8) {
        this.selectedTab = 'gemchest';
        this.$store.commit('SET_MOBILE_NAVIGATION_TAB', this.selectedTab)
      }
      if (this.current_tab_mobile_navigation === 'gemchest') this.loadingOff();

    },
    async notificationHandler() {
      this.$store.commit('SET_OPEN_NOTIFICATION', !this.openNotification);
      if (this.openNotification && this.notifications_count) {
        await this.updateNotifications(localStorage.getItem('account'));
      }
    },
  },
  watch: {
    current_tab_mobile_navigation(val) {
      this.selectedTab = val;
    }
  },
  created() {
    if (window.innerWidth < 768) {
      this.navigationCreateFunctionality();
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/sass/imports/main/variables";
@import "@/assets/sass/imports/main/mixins";
@import "@/assets/sass/imports/main/general";
@import "@/assets/sass/imports/main/extendsStart";
@import "@/assets/sass/imports/main/extendsEnd";


.mobile_navigation {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: flex-end;
}


.mobile_view_navigation_links {
  display: flex;
  align-items: flex-end;
  width: 100%;

  .center_nav_link {
    border-left: 1px solid $black;
    border-right: 1px solid $black;
  }

  .mobile_view_navigation_button {
    width: 33.3333%;
    height: 50px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: $orangeF4;
    border-top: 1px solid $black;
    border-bottom: none;
    font-family: $bold;
    font-size: 16px;
    line-height: 20px;
    color: $white;
    cursor: pointer;
    -webkit-box-shadow: inset -1px -1px 20px -6.5px #bc9031;
    -moz-box-shadow: inset -1px -1px 20px -6.5px #bc9031;
    box-shadow: inset -1px -1px 20px -6.5px #bc9031;

    @include mediaTo($size768) {
      height: 45px;
    }
  }

  .mobile_nav_notification_count {
    position: absolute;
    top: -15px;
    right: 10%;
    z-index: 3;
    min-width: 30px;
    height: 25px;
    text-align: center;
    background: red;
    border: 3px solid #fa5050;
    border-radius: 6px;
    font-family: $bold;
    font-size: 15px;
    line-height: 20px;
    color: #ffffff;
    filter: drop-shadow(0px 4px 0px #a10000);
    -webkit-filter: drop-shadow(0px 4px 0px #a10000);
    cursor: pointer;

  }
}
</style>
