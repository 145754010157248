import axios from "axios";
import NetworkConfig from "@/contract/NetworkConfig";

const currentNetwork = localStorage.getItem('current_network') || 'polygon'

export default {
    getCreateData({token = `${NetworkConfig[currentNetwork].defaultToken}`, ntw = currentNetwork}) {
        return axios.get(`/chest/${ntw}/${token}`);
    },
    createChest(payload) {
        return axios.post('/chest', payload);
    },
    updateCreatedChestInfo(payload) {
        return axios.post('/chest/lock', payload);
    },
    getLastTenChests(query) {
        return axios.get('/chest/lastChests', {params: query});
    },
    getMyChests(query) {
        return axios.get(`/chest/myChest`, {params: query});
    },
    getChestByGiftType(query) {
        return axios.get('/chest/byGiftType', {params: query});
    },
    getChest(query) {
        return axios.get('/chest/chestByUuid', {params: query})
    },
    loginMetamask(payload) {
        return axios.post('/user/loginMetamask', payload);
    },
    getNonce(payload) {
        return axios.post('/user/getNonce', payload);
    },
    sendSignature(payload) {
        return axios.post('/user/signature', payload);
    },
    addFavorite(payload) {
        return axios.post('/favorite', payload)
    },
    removeFavorite(id) {
        return axios.delete(`/favorite/${id}`)
    },
    getFavoriteList() {
        return axios.get('/favorite')
    },
    claimGift({jwt, address}) {
        return axios.post(`/chest/claim/${jwt}`, {address})
    },
    getNotifications(params) {
        return axios.get(`/notification`, {params: params})
    },
    updateNotifications(address) {
        return axios.put(`/notification/${address}`)
    },
    deleteNotification(id) {
        return axios.delete(`/notification/${id}`)
    },
    getTrends(dateQuery){
        return axios.get('/chest/statistics',{params: dateQuery})
    }
};
