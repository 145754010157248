export default {
    agor: {
        chainId: `0x${Number(421613).toString(16)}`,
        chainName: "Arbitrum Goerli",
        nativeCurrency: {
            name: "Arbitrum Goerli Ether",
            symbol: "AGOR",
            decimals: 18,
        },
        rpcUrls: [
            "https://goerli-rollup.arbitrum.io/rpc/"
        ],
        blockExplorerUrls: ["https://goerli.arbiscan.io/"],
    },
}
