<template>
  <div class="notifications" v-if="openNotification">
    <NotificationBar />
  </div>
  <div class="white_overlay" v-if="white_overlay">
    <PageLoader :loading="loading" class="general_loading" />
  </div>
  <div class="page_view">
    <div>
      <PageHeader />
      <MessagePopup v-if="message_popup_data.message" />
      <router-view v-show="!loading" />
    </div>
    <div class="footer_wrapper">
      <PageFooter />
    </div>
    <div class="mobile_view_navigation">
      <MobileNavigation />
    </div>
  </div>
  <div class="overlay" v-show="overlay"></div>
</template>
<script>
import PageHeader from "@/components/Header/PageHeader.vue";
import PageFooter from "./components/Footer/PageFooter.vue";
import NotificationBar from "@/views/Notifications/NotificationBar";
import { mapActions, mapGetters } from "vuex";
import PageLoader from "@/components/PageLoader/PageLoader.vue";
import MobileNavigation from "@/components/MobileNavigation/MobileNavigation.vue";
import MessagePopup from "@/components/UI/MessagePopup.vue";

export default {
  components: {
    MessagePopup,
    MobileNavigation,
    PageHeader,
    PageFooter,
    NotificationBar,
    PageLoader,
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters([
      "openNotification",
      "overlay",
      "white_overlay",
      "loading",
      "createData",
      "message_popup_data",
    ]),
  },
  methods: {
    ...mapActions(["getCreateData", "loadingOn", "loadingOff"]),
  },
  watch: {
    "window.ethereum.isMetaMask": {
      handler(val) {
          console.log(val)
      },
      deep: true,
    },
  },
  async created() {
    this.loadingOn();
    this.$store.commit("SET_ACCOUNT", localStorage.getItem("account"));
    if (localStorage.getItem("token")) {
      this.$store.commit("SET_LOGIN", true);
    } else {
      this.$store.commit("SET_LOGIN", false);
    }
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/imports/main/variables";
@import "@/assets/sass/imports/main/mixins";
@import "@/assets/sass/imports/main/general";
@import "@/assets/sass/imports/main/extendsStart";
@import "@/assets/sass/imports/main/extendsEnd";

.general_loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.white_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  overflow-y: hidden;
  z-index: 999999999;
  background: #fff;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 2;
  background: rgba(0, 0, 0, 0.6);
}

.notifications {
  &::before {
    content: "";
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 99999999;
    background: rgba(0, 0, 0, 0.8);
  }
}

.page_view {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer_wrapper {
  @include mediaRange($size320, $size768) {
    display: none;
  }
}
.mobile_view_navigation {
  @include mediaFrom($size768) {
    display: none;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
