<template>
  <div class="loader-spanne-25"></div>
<!--  <div class="spinner"></div>-->
</template>

<script>
export default {
  name: "CustomLoader"
}
</script>

<div class="spinner"></div>

<style scoped>
.loader-spanne-25 {
  width: 70px;
  height: 5px;
  background: repeating-linear-gradient(90deg, #fff 0 calc(25% - 5px), #0000 0 25%) left/calc(4*100%/3) 100%;
  animation: anm-LL-25-move 0.6s infinite linear;
}
@keyframes anm-LL-25-move {
  100% {
    background-position: right
  }
}
/*.spinner {*/
/*  width: 40px;*/
/*  height: 40px;*/
/*  display: grid;*/
/*  color: #39fcea;*/
/*  background: radial-gradient(farthest-side, currentColor calc(100% - 4.8px),#0000 calc(100% - 4px) 0);*/
/*  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 10.4px),#000 calc(100% - 9.6px));*/
/*  border-radius: 50%;*/
/*  animation: spinner-sm4bhi 2s infinite linear;*/
/*}*/

/*.spinner::before,*/
/*.spinner::after {*/
/*  content: "";*/
/*  grid-area: 1/1;*/
/*  background: linear-gradient(currentColor 0 0) center,*/
/*  linear-gradient(currentColor 0 0) center;*/
/*  background-size: 100% 8px,8px 100%;*/
/*  background-repeat: no-repeat;*/
/*}*/

/*.spinner::after {*/
/*  transform: rotate(45deg);*/
/*}*/

/*@keyframes spinner-sm4bhi {*/
/*  100% {*/
/*    transform: rotate(1turn);*/
/*  }*/
/*}*/
</style>