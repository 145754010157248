import axios from "axios";
import store from "@/store";

axios.defaults.baseURL = process.env.VUE_APP_API_URL || "http://localhost:8080";


axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error && error.response.status === 401) {
            store.dispatch('removeItemsFromStorage')
        }
        return Promise.reject(error);
    }
);

axios.interceptors.request.use(
    (config) => {
        if (localStorage.getItem("token")) {
            config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

