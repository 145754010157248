<template>
  <div>
    <div
      class="my_notifications"
      :style="window_width > 768 ? `left: ${position}px` : ''"
      v-click-away="outsideClickHandler"
    >
      <div class="notification_control_position">
        <div
          class="notification_inner_block"
          @scroll="notificationsScrollHandler"
        >
          <div
            class="notification_block_wrapper"
            v-for="(data, index) in notifications"
            :key="data"
            :style="
              notifications.length === 1
                ? 'margin-top: 0;margin-bottom: 0'
                : index === 0
                ? 'margin-top: 0;'
                : index === notifications.length - 1
                ? 'margin-bottom: 0'
                : ''
            "
          >
            <NotificationBlock :notification_data="data" />
          </div>
          <div
            class="notification_not_found_text stroke_1"
            v-if="!notifications.length"
          >
            No Notifications Yet.
          </div>
        </div>
        <div class="notification_loading" v-if="loading">
          <PageLoader :loading="loading" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NotificationBlock from "@/views/Notifications/components/NotificationBlock";
import PageLoader from "@/components/PageLoader/PageLoader.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    NotificationBlock,
    PageLoader,
  },

  computed: {
    ...mapGetters([
      "openNotification",
      "notifications",
      "notifications_count",
      "notifications_whole_count",
    ]),
    notificationParams() {
      return {
        offset: this.notifications.length,
      };
    },
  },
  data() {
    return {
      notificationData: [],
      position: null,
      window_width: 0,
      isScrolling: null,
      loading: false,
    };
  },
  methods: {
    ...mapActions(["getNotifications"]),
    async outsideClickHandler() {
      if (this.openNotification == true) {
        await this.getNotifications({ offset: 0 } );
      }
      this.$store.commit("SET_OPEN_NOTIFICATION", false);
    },
    loadingHandler(value) {
      this.loading = value;
    },
    async notificationsScrollHandler(e) {
      window.clearTimeout(this.isScrolling);

      const scrollDirectionCheck =
        e.target.scrollTop + e.target.clientHeight == e.target.scrollHeight;
      const notificationLengthCheck =
        this.notificationParams.offset <= this.notifications_whole_count;

      this.isScrolling = setTimeout(async () => {
        if (scrollDirectionCheck && notificationLengthCheck) {
          this.loadingHandler(true);
          await this.getNotifications(this.notificationParams);
          this.loadingHandler(false);
        }
      }, 120);
    },
  },
  created() {
    this.window_width = window.innerWidth;
    if (this.window_width >= 768) {
      this.position =
        document.querySelector(".header_notification").getBoundingClientRect()
          .left + 17;
    }
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/imports/main/_variables.scss";
@import "@/assets/sass/imports/main/mixins";
@import "@/assets/sass/imports/main/general";
@import "@/assets/sass/imports/main/extendsStart";
@import "@/assets/sass/imports/main/extendsEnd";

.my_notifications {
  position: absolute;
  top: 100px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  @include mediaRange($size320, $size768) {
    margin-top: 0;
    top: 20px;
    width: 100%;
  }

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    z-index: 99999999;
    top: -20px;
    border-width: 0 20px 30px 20px;
    border-color: transparent transparent #ba99f5 transparent;
    @include mediaRange($size320, $size768) {
      display: none;
    }
  }

  .notification_inner_block {
    max-height: 502px;
    overflow: auto;
    @include mediaRange($size320, $size768) {
      max-height: 70vh;
    }
  }

  .notification_inner_block::-webkit-scrollbar {
    background: $purpleBA;
    border-radius: 6px;
    display: none;
  }

  .notification_control_position {
    position: absolute;
    width: 359px;
    background: $purpleBA;
    z-index: 99999999;
    border-radius: 6px;
    padding: 15px 0 15px 14px;
    @include mediaRange($size320, $size768) {
      width: 90%;
      padding: 15px 14px;
      max-height: 75vh;
    }

    .notification_block_wrapper {
      margin: 10px 0;
    }
  }
}

.notification_not_found_text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $bold;
  font-size: 18px;
  line-height: 20px;
  color: $white;
}

.notification_page_wrapper {
  position: absolute;
  right: 0;
  left: 0;
  width: 1400px;
  display: flex;
  margin: 0 auto;
  justify-content: flex-end;

  .notification_bar {
    position: fixed;
    height: 100vh;
    overflow: auto;
    z-index: 999999999;
    width: 482px;

    .notification_bar_header {
      background: $siteColor;
      height: 117px;
      display: flex;
      align-items: center;
      padding: 0 20px;

      .notification_bar_header_content {
        font-family: $bold;
        font-size: 36px;
        line-height: 54px;
        color: $white;
        display: flex;
        align-items: center;
      }

      .icon_notification {
        color: $yellowF9;
        margin-right: 15px;
      }
    }

    .notification_content {
      background: $white;
      padding: 20px 20px;
      height: 60vh;
      overflow: auto;
    }
  }
}

.slide-in-top {
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.notification_loading {
  display: flex;
  justify-content: center;
  margin: 10px 0;

  .bouncing-loader {
    img {
      width: 44px !important;
      height: 44px !important;
    }
  }
}
</style>
