export default {
    goerli: {
        chainId: `0x${Number(5).toString(16)}`,
        chainName: "Ethereum Testnet Goerli",
        nativeCurrency: {
            name: "Goerli Ether",
            symbol: "ETH",
            decimals: 18,
        },
        rpcUrls: [
            "https://rpc.goerli.mudit.blog/",
        ],
        blockExplorerUrls: ["https://goerli.etherscan.io"],
    },
}
