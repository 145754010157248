import arbitrum from "@/contract/NetworkConfig/arbitrum";
import ethereum from "@/contract/NetworkConfig/ethereum";
import polygon from "@/contract/NetworkConfig/polygon";
import optimism from "@/contract/NetworkConfig/optimism";

export default {
    ...polygon,
    ...ethereum,
    ...arbitrum,
    ...optimism
}
