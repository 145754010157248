export default {
  "welcome_title_part1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигать целей"])},
  "welcome_title_part2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["стало проще"])},
  "welcome_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокируйте токены в НФТ сундуке и установите желаемую вами цену, по достижении которого сундук автоматически разблокирует токены."])},
  "why_gemchest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почему GemChest?"])},
  "roadmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Планы"])},
  "connect_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключить Кошелек"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])},
  "all_chests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все Сундуки"])},
  "my_chests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои Сундуки"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
  "nothing_was_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сундуков пока нет"])},
  "connect_wallet_to_see_your_chests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключите кошелек, чтобы просмотреть свои сундуки"])},
  "create_chest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать Сундук"])},
  "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Шаг"])},
  "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Шаг"])},
  "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Шаг"])},
  "current_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущая цена"])},
  "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токен"])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["и"])},
  "investment_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма инвестиций"])},
  "and_token_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["и количество токенов"])},
  "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цели"])},
  "chest_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок блокировки"])},
  "lock_term_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальный срок хранения токенов запертыми в сундуке"])},
  "creation_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плата за создание"])},
  "growth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рост"])},
  "token_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена токена"])},
  "roi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рентабельность инвестиций"])},
  "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безопасность"])}
}