export default {
    arbitrum: {
        image: 'arbitrum.png',
        defaultToken: 'ARB',
        testnet: {
            key: 'agor',
            blockExplorerTxBaseUrl: 'https://goerli.arbiscan.io/tx/',
            openseaNftBaseUrl: 'https://testnets.opensea.io/assets/goerli/0x99e114a6BB173B9B589786ceAeBF9ceF7276Eaae/',
        },
        mainnet: {
            key: 'arb1',
            blockExplorerTxBaseUrl: 'https://arbiscan.io/tx/',
            openseaNftBaseUrl: 'https://opensea.io/assets/arbitrum/0x75eabDE6A42FF57E4DA7703FedA7756c510b954C/',
        },
    },
}
