<template>
  <div class="stroke_1" v-if="notifications_count" @click="$emit('notificationHandler')">{{ notifications_count }}</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "NotificationCount",
  computed: {
    ...mapGetters(["notifications_count"]),
  }
}
</script>

<style scoped>

</style>
